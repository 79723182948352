export const experienceTypeList = ['Internship', 'Recent Grad', 'Entry level', 'Associate', 'Mid-Senior level', 'Director', 'Executive'];
export const degreesList = ['High school','Associate\'s degree','Bachelor\'s degree','Master\'s degree','PhD'];
export const experienceLevelList = ['Internship', 'Recent Grad', 'Entry level', 'Associate', 'Mid-Senior level', 'Director', 'Executive'];
export const jobTypeList = ['Full-time', 'Part-time', 'Contract', 'Temporary', 'Volunteer', 'Internship'];
export const bSizeList = ['Startup', 'SME'];
export const locationList = ['Los Angeles, CA', 'San Diego, CA', 'Santa Clara, CA', 'San Francisco, CA', 'Newark, NJ', 'Jersey City, NJ', 'New York, NY', 'Chicago, IL', 'Houston, TX', 'Philadelphia, PA', 'Columbus, OH'];
export const rolesList = ['Marketing Assistant', 'Marketing Coordinator', 'Market Research', 'Social Media Coordinator','SEO dev', 'Front End Developer', 'Content Creator', 'Software Developer', 'Graphic Designer', 'DevOps Engineer', 'Key Account', 'Sales Assistant', 'Chemical Engineer', 'UI/UX Designer'];
export const skillsList = ['Database management', 'Adobe software','Network security', 'SEO/SEM marketing','Statistical analytics','Data mining','Mobile development', 'UX/UI design', 'Storage systems and management', 'Photoshop Adobe',
    'Time management',
    'Effective communication',
    'Emotional intelligence',
   'Conflict management',
    'Teamwork skills',
    'Stress management',
    'Problem-solving',
    'Productivity & organization',
    'Critical thinking',
    'Attention to detail',
    'Adaptability',
    'Data analysis',
    'Web analytics',
    'SEO/SEM',
    'Wordpress',
    'Email marketing',
    'Web scraping',
    'CRO and A/B Testing',
    'Data visualization & pattern-finding through critical thinking',
    'Search Engine and Keyword Optimization',
    'Project/campaign management',
    'Social media and mobile marketing',
    'Paid social media advertisements',
    'B2B Marketing',
    'The 4 P-s of Marketing',
    'Consumer Behavior Drivers',
    'Brand management',
    'Creativity',
    'Copywriting',
    'Storytelling',
    'Sales',
    'CMS Tools',
  'Six Sigma techniques',
    'The McKinsey 7s Framework',
    'Porter’s Five Forces',
'PESTEL',
'Emotional Intelligence',
'Dealing with work-related stress',
'Motivation',
'Task delegation',
'Technological savviness',
'People management',
'Business Development',
'Strategic Management',
'Negotiation',
'Planning',
'Proposal writing',
'Problem-solving',
'Innovation',
'Charisma',
'Customer Relationship Management (CRM)',
'Cold-calling',
'Negotiation',
'Public speaking',
'Closing',
'Lead generation',
'Buyer-Responsive selling',
'Buyer engagement',
'Product knowledge',
'Persuasion',
'Effective communication and sociability',
'Empathy',
'Social media/digital communication',
'Teamwork',
'Time management',
'Adobe Creative Suite: Illustrator, InDesign, Photoshop',
'Dreamweaver',
'Infographics',
'HTML & CSS',
'Photo editing',
'Typography: spacing, line height, layout, choosing fonts',
'Storyboarding',
'Targeting and marketing through visual communications',
'Logo creation',
'Digital printing',
'Integration of visual communication in social media platforms',
'Creativity',
'Attention to detail & aesthetics',
'Interactive media design',
'Color sense & theory',
'Ad design',
'Active listening',
'Microsoft Office Pack: Word, Excel, Access, Publisher, Outlook, Powerpoint',
'Filing and paper management',
'Data entry',
'Bookkeeping through Excel or TurboTax',
'Research and data analysis',
'Basic knowledge of user interface communication',
'Technical writing',
'Cloud networking and file sharing',
'Microsoft Excel (Advanced)',
'Enterprise Resource Planning',
'Big Data Analysis & SQL',
'Know Your Customers (KYC)',
    'ReactJS',
    'Angular',
    'NodeJS',
'Cognos Analytics (IBM)',
'Visual Basic',
'Accounting Software',
'Revenue recognition',
'Anti Money Laundering',
'Clear communication',
'General business knowledge',
'Numerical competence',
'Accuracy',
'Attention to detail',
'Updated curriculum knowledge',
'Research & Data analysis',
'Communication',
'Educational platforms (software like Elearn)',
'Stress management',
'Technological & digital literacy',
'Patience',
'Critical thinking',
'Enthusiasm',
'Motivation',
'CSS preprocessors',
'Javascript',
'Wordpress',
'Graphic User Interfaces (GUI)',
'Git/Version control (Github, gitlab)',
'Search Engine Optimization (SEO)',
'Application Programming Interface (API)',
'Adobe Photoshop, InDesign',
'Content Management Systems (CMS)',
'Testing/Debugging',
'Responsive design principles',
'SQL (a must) and Hive (optional)',
'Functional Programming language (R, Python, Scala, Matlab)',
'STATA, SPSS, SAS',
'Data Mapping',
'Entity Relationship Diagrams',
'Wireframes',
'Big Data tools',
'Microsoft Visio',
'Agile Business Analysis',
'Machine learning',
'System Context Diagrams',
'Business Process Modeling',
'Technical and non-technical communication',
'Mathematics',
'CPR',
'Patient care and assistance',
'Paperwork/record-keeping abilities',
'Compassion',
'Advanced Cardiac Life Support (ACLS)',
'Telemetry',
'Attention to detail',
'Physical endurance',
'Acute care',
'Infection control',
'Surgery preparation' ];
export const industryList = ['Accounting', 'Engineering', 'Law','Architecture','Medicine','Business','Retail','Media','Information Technology','Education','Sales','Science','Transport','Food and Bevarage', 'Healthcare',
    "Airlines/Aviation", "Alternative Medicine","Animation","Apparel & Fashion","Architecture & Planning","Arts and Crafts","Automotive",
    "Aviation & Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies and Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Defense & Space",
    "Design",
    "Education Management",
    "E-Learning",
    "Electrical/Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Facilities Services",
    "Financial Services",
    "Fine Art",
    "Food & Beverages",
    "Food Production",
    "Fund-Raising",
    "Furniture",
    "Gambling & Casinos",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness and Fitness",
    "Higher Education",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import and Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology and Services",
    "Insurance",
    "International Affairs",
    "International Trade and Development",
    "Internet",
    "Investment Banking",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Logistics and Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Market Research",
    "Marketing and Advertising",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Devices",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Motion Pictures and Film",
    "Museums and Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Non-Profit Organization Management",
    "Oil & Energy",
    "Online Media",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging and Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary Education",
    "Printing",
    "Professional Training & Coaching",
    "Program Development",
    "Public Policy",
    "Public Relations and Communications",
    "Public Safety",
    "Publishing",
    "Real Estate",
    "Recreational Facilities and Services",
    "Religious Institutions",
    "Renewables & Environment",
    "Research",
    "Restaurants",
    "Retail",
    "Security and Investigations",
    "Semiconductors",
    "Sporting Goods",
    "Sports",
    "Staffing and Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Tobacco",
    "Translation and Localization",
    "Transportation/Trucking/Railroad",
    "Utilities",
    "Venture Capital & Private Equity",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine and Spirits",
    "Wireless",
    "Writing and Editing"]
