import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class Spinner extends React.Component {


    render() {
        const {showSpinner} = this.props.dataReducer;

        return (
            <div className='spinner-wrapper' hidden={!showSpinner}>
                <span className='spinner' />
            </div>
        )
    }

}


const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(withRouter(Spinner));
