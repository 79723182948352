import React from 'react'
import { Switch, Route } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import '../styles/style.scss';
import Amplify from '@aws-amplify/core';
import config from '../aws-exports';

Amplify.configure(config);

const App = ({ history, routes }) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                {routes.routes.map(route => {
                    if (route.path === '/') {
                        return (<Route key={`route_${route.path}`} exact path='/' component={route.component} />)
                    } else {
                        return (<Route key={`route_${route.path}`} path={route.path} component={route.component} />)
                    }
                })}
            </Switch>
        </ConnectedRouter>
    )
};


export default App;
