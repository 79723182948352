import React from 'react'
import { connect } from 'react-redux'
import '../../styles/style.scss';
import {faPencilAlt, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {industryList} from '../../assets/libs';
import { withRouter} from "react-router";
import {Storage} from "@aws-amplify/storage";
import { S3Image } from 'aws-amplify-react';


class MyAccountRecruiter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editingPersonalInfo: false,
            editingCompanyInfo: false,
            editingCompanyDetails: false,

            dropdownShowIndustry: false,

            name: null,
            lastName: null,
            email: null,
            companyName: null,
            address: null,
            phone_number: null,
            linkedIn: null,
            website: null,
            facebook: null,
            twitter: null,
            companyIndustry: null,
            companyValues: null,
            companyBenefits: null,
            image: null
        };
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            const {userName, userEmail, userProfileInfo} = this.props.dataReducer;
            if (!!userProfileInfo) {
                this.setState({
                    name: userName,
                    lastName: userProfileInfo.lastName,
                    email: userEmail,
                    companyName: userProfileInfo.companyName,
                    address: userProfileInfo.address,
                    phone_number: userProfileInfo.phone_number,
                    linkedIn: userProfileInfo.linkedIn,
                    website: userProfileInfo.website,
                    facebook: userProfileInfo.facebook,
                    twitter: userProfileInfo.twitter,
                    companyIndustry: userProfileInfo.companyIndustry,
                    companyValues: userProfileInfo.companyValues,
                    companyBenefits: userProfileInfo.companyBenefits,
                    image: userProfileInfo.imageUrl
                });
                if (userProfileInfo.imageUrl) {
                    const imageUrl = await Storage.vault.get(userProfileInfo.imageUrl);
                    this.setState({
                        image: imageUrl
                    })
                }
            }
        }
    }

    async componentDidMount() {
        const {userName, userEmail, userProfileInfo} = this.props.dataReducer;
        if (!!userProfileInfo) {
            this.setState({
                name: userName,
                lastName: userProfileInfo.lastName,
                email: userEmail,
                companyName: userProfileInfo.companyName,
                address: userProfileInfo.address,
                phone_number: userProfileInfo.phone_number,
                linkedIn: userProfileInfo.linkedIn,
                website: userProfileInfo.website,
                facebook: userProfileInfo.facebook,
                twitter: userProfileInfo.twitter,
                companyIndustry: userProfileInfo.companyIndustry,
                companyValues: userProfileInfo.companyValues,
                companyBenefits: userProfileInfo.companyBenefits,
                image: userProfileInfo.imageUrl
            });
            if (userProfileInfo.imageUrl) {
                const imageUrl = await Storage.vault.get(userProfileInfo.imageUrl);
                this.setState({
                    image: imageUrl
                })
            }
        }
    }

    dropdownChooseIndustry= (event) => {
        event.preventDefault();
        this.setState({
            dropdownShowIndustry: !this.state.dropdownShowIndustry,
            companyIndustry: event.target.value
        })
    };

    onChangeInput = (event, item) => {
        if(item === 'lastName') {
            this.setState({
                lastName: event.target.value
            })
        }
        else if(item === 'companyName') {
            this.setState({
                companyName: event.target.value
            })
        }
        else if(item === 'address') {
            this.setState({
                address: event.target.value
            })
        }
        else if(item === 'phone_number') {
            this.setState({
                phone_number: event.target.value
            })
        }
        else if(item === 'linkedIn') {
            this.setState({
                linkedIn: event.target.value
            })
        }
        else if(item === 'website') {
            this.setState({
                website: event.target.value
            })
        }
        else if(item === 'facebook') {
            this.setState({
                facebook: event.target.value
            })
        }
        else if(item === 'twitter') {
            this.setState({
                twitter: event.target.value
            })
        }
        else if(item === 'companyValues') {
            this.setState({
                companyValues: event.target.value
            })
        }
        else if(item === 'companyBenefits') {
            this.setState({
                companyBenefits: event.target.value
            })
        }
    };

    updateProfileInfo = () => {
        const data = {
            name: this.state.name,
            lastName: this.state.lastName,
            email: this.state.email,
            companyName: this.state.companyName,
            address: this.state.address,
            phone_number: this.state.phone_number,
            linkedIn: this.state.linkedIn,
            website: this.state.website,
            facebook: this.state.facebook,
            twitter: this.state.twitter,
            companyIndustry: this.state.companyIndustry,
            companyValues: this.state.companyValues,
            companyBenefits: this.state.companyBenefits,
            imageUrl: this.state.image
        };
        this.props.dispatch({
            type: 'UPDATE_USER_DATA',
            data
        });
        this.setState({
            editingPersonalInfo: false,
            editingCompanyInfo: false,
            editingCompanyDetails: false
        })
    };

    render() {
        let width = window.innerWidth;
        const { userName, userLastName, userProfileInfo, loaded, username_ } = this.props.dataReducer;

        if(!userProfileInfo && !loaded) {
            return <div className='spinner-wrapper'>
                <span className='spinner' />
            </div>
        }

        return (
            <main>
                <div className="section-above">
                    <div className="profile-img" hidden={width>500}>
                        { this.state.image ?
                            <S3Image
                                imgKey={"profile-pic-" + username_}
                                alt="user avatar"
                            /> :
                            <img src={require('../../assets/images/no-profile-pic.jpg')} alt="" />
                        }
                        <p>{userName + " " + userLastName}</p>
                    </div>
                    <div className="share">
                        <img src={require('../../assets/images/plus-icon.svg')} alt="" />
                        <label>Post a new job</label>
                    </div>
                </div>
                <div className="section-wrap">
                    <div className="info-section smaller-section">
                        <div className="section-title">
                            <h6>Personal Information</h6>
                            {
                                this.state.editingPersonalInfo ?
                                    <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo}  icon={faSave} />
                                    :
                                    <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingPersonalInfo: true})}} icon={faPencilAlt} />
                            }
                        </div>
                        <div className="info-item">
                            <label>Name:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.name} disabled={true} /> : <p>{this.state.name || "/"}</p> }
                        </div>
                        <div className="info-item">
                            <label>Last Name:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.lastName} onChange={(event) => this.onChangeInput(event, 'lastName')} /> : <p>{this.state.lastName || "/"}</p> }
                        </div>
                        <div className="info-item">
                            <label>Email:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.email} disabled={true} /> : <p>{this.state.email}</p> }
                        </div>
                        <div className="info-item">
                            <label>Phone:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.phone_number} onChange={(event) => this.onChangeInput(event, 'phone_number')}  /> : <p>+1 {this.state.phone_number || "/"}</p> }
                        </div>
                    </div>
                    <div className="info-section smaller-section">
                        <div className="section-title">
                            <h6>Company Information</h6>
                            {
                                this.state.editingCompanyInfo ?
                                    <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                    :
                                    <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingCompanyInfo: true})}} icon={faPencilAlt} />
                            }
                        </div>
                        <div className="info-item">
                            <label>Name:</label>
                            { this.state.editingCompanyInfo ? <input defaultValue={this.state.companyName} onChange={(event) => this.onChangeInput(event, 'companyName')}  /> : <p>{this.state.companyName || "/"}</p> }
                        </div>
                        <div className="info-item">
                            <label>Address:</label>
                            { this.state.editingCompanyInfo ? <input defaultValue={this.state.address} onChange={(event) => this.onChangeInput(event, 'address')} /> : <p>{this.state.address || "/"}</p> }
                        </div>
                        <div className="info-item">
                            <label>Website:</label>
                            { this.state.editingCompanyInfo ? <input defaultValue={this.state.website} onChange={(event) => this.onChangeInput(event, 'website')} /> : <p>{this.state.website || "/"}</p> }
                        </div>
                        <div className="info-item">
                            <label>Facebook:</label>
                            { this.state.editingCompanyInfo ? <input defaultValue={this.state.facebook} onChange={(event) => this.onChangeInput(event, 'facebook')}  /> : <p>{this.state.facebook || "/"}</p> }
                        </div>
                        <div className="info-item">
                            <label>LinkedIn:</label>
                            { this.state.editingCompanyInfo ? <input defaultValue={this.state.linkedIn} onChange={(event) => this.onChangeInput(event, 'linkedIn')}  /> : <p>{this.state.linkedIn || "/"}</p> }
                        </div>
                    </div>
                </div>
                <div className="info-section">
                    <div className="section-title">
                        <h6>Company Details</h6>
                        {
                            this.state.editingCompanyDetails ?
                                <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                :
                                <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingCompanyDetails: true})}} icon={faPencilAlt} />
                        }
                    </div>
                    <h5>{this.state.companyName || ""}</h5>
                    <div className="info-item">
                        <label>Company Industry:</label>
                        { this.state.editingCompanyDetails ?
                            <div className="text-input dropdown-input">
                                <div className="dropdown-wrap">
                                    <input type="text" list="cities" className="type-dropdown" value={this.state.companyIndustry || ''}
                                           onChange={this.dropdownChooseIndustry}
                                           aria-describedby="basic-addon1"/>
                                    <datalist id="cities">
                                        {
                                            industryList && industryList.map((item, index) => {
                                                return <option key={index}>{item}</option>
                                            })
                                        }
                                    </datalist>
                                </div>
                            </div>
                        : <p>{this.state.companyIndustry || "/"}</p> }
                    </div>
                    <div className="info-item">
                        <label>Company Values:</label>
                        { this.state.editingCompanyDetails ?
                            <textarea defaultValue={this.state.companyValues} onChange={(event) => this.onChangeInput(event, 'companyValues')} />
                            : <p>{this.state.companyValues || "/"}</p> }
                    </div>
                    <div className="info-item">
                        <label>Company Benefits:</label>
                        { this.state.editingCompanyDetails ?
                            <input defaultValue={this.state.companyBenefits} onChange={(event) => this.onChangeInput(event, 'companyBenefits')} /> :
                                <p>{this.state.companyBenefits || "/"}</p>
                        }
                    </div>
                </div>

            </main>
        )
    }


}


const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(withRouter(MyAccountRecruiter));
