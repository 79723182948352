import React from 'react'
import { connect } from 'react-redux'
import Footer from "../../components/footer";
import {Link, withRouter} from 'react-router-dom';
import Spinner from "../../components/spinner";

class ConfirmCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: 60,
            code: 0,
            error: false,
            errorMsg: ""
        };
        this.countDown = null;
    }

    componentDidMount() {
        this.setInterval();
    }

    componentWillUnmount() {
        clearInterval(this.countDown);
    }

    setInterval = () => {
        this.countDown = setInterval(() => {
            if(this.state.timer !== 0) {
                this.setState({
                    timer: this.state.timer - 1
                })
            }
        }, 1000);
    };

    resendCode = () => {
        this.setState({
            timer: 60
        });
        const login = this.props.authReducer.email ? this.props.authReducer.email : window.localStorage.getItem('email');
        this.props.dispatch({
            type: 'RESEND_VERIFY_REQUEST',
            payload: { login }
        })
    };

    handleChange = (event) => {
        this.setState({
            code: event.target.value
        })
    };

    onConfirm = () => {
        const login = this.props.authReducer.email ? this.props.authReducer.email : window.localStorage.getItem('email');
        const code = this.state.code;
        this.props.dispatch({
            type: 'VERIFY_REQUEST',
            payload: { login, code }
        });
    };

    render() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        const { errorVerify, email } = this.props.authReducer;
        const {showSpinner} = this.props.dataReducer;
        if(showSpinner) {
            return <Spinner />
        }
        return (
            <div className="confirm-page">
                <header>
                    <div className="header">
                        <Link to={{ pathname: "/" }}>
                            <img src={require('../../assets/images/long-logo.svg')} alt="" className="human1"/>
                        </Link>
                    </div>
                </header>
                <div className="confirm-content">
                    <h1>Enter verification code</h1>
                    <p>Please enter the code we just sent to</p>
                    <span>{email}</span>
                    <input type="number" min="1" maxLength="1"  pattern="[0-9]" className="code-input" placeholder="- - - - - -" onChange={this.handleChange} id="code-input" />
                    <div className="confirm-btn" onClick={this.onConfirm}>Confirm</div>
                    {
                        errorVerify !== null && <label className="error-msg">{errorVerify}</label>
                    }
                    {
                        this.state.timer !== 0 &&  <p className="wait">Haven't got an email? <br/>  Please wait <span>{this.state.timer}</span> seconds for the code to arrive.</p>
                    }
                    {
                        this.state.timer === 0 && <p className="resend-p">Haven't got an email? <span className="resend" onClick={this.resendCode}>Resend the code.</span></p>
                    }
                </div>
                <Footer />
            </div>
        )
    }


}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(withRouter(ConfirmCode));
