import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Footer from "../footer";
import {Link, withRouter} from 'react-router-dom';
import {industryList} from '../../assets/libs';
import {Storage} from "@aws-amplify/storage";

class ProfileInfoRecruiter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            showImage: false,
            dropdownShowIndustry: false,
            imgFile: null,

            address: null,
            phone_number: null,
            linkedIn: null,
            website: null,
            facebook: null,
            twitter: null,
            companyIndustry: null,
            companyValues: null,
            companyBenefits: null,

            showError: {}
        };
    }

    setFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                image: URL.createObjectURL(event.target.files[0]),
                showImage: true,
                imgFile: event.target.files[0]
            });
        }
    };

    removeImg = () => {
        this.setState({
            image: null,
            imgFile: null,
            showImage: false
        });
    };

    dropdownShowIndustry = () =>  {
        this.setState({
            dropdownShowIndustry: !this.state.dropdownShowIndustry
        })
    };

    dropdownChooseIndustry= (event) => {
        event.preventDefault();
        let showError = this.state.showError;
        showError['companyIndustry'] = false;
        this.setState({
            dropdownShowIndustry: !this.state.dropdownShowIndustry,
            companyIndustry: event.target.value,
            showError: showError
        })
    };

    onInputAddress = (event) => {
        let showError = this.state.showError;
        showError['address'] = false;
        this.setState({
            address: event.target.value,
            showError: showError
        })
    };

    onInputPhone = (event) => {
        let showError = this.state.showError;
        showError['phone_number'] = false;
        this.setState({
            phone_number: event.target.value,
            showError: showError
        })
    };

    onInputLinkedIn = (event) => {
        this.setState({
            linkedIn: event.target.value,
        })
    };

    onInputWeb = (event) => {
        this.setState({
            website: event.target.value,
        })
    };

    onInputFb = (event) => {
        this.setState({
            facebook: event.target.value,
        })
    };

    onInputValues = (event) => {
        let showError = this.state.showError;
        showError['companyValues'] = false;
        this.setState({
            companyValues: event.target.value,
            showError: showError
        })
    };

    onInputBenefits = (event) => {
        this.setState({
            companyBenefits: event.target.value
        })
    };

    onInputTwitter = (event) => {
        this.setState({
            twitter: event.target.value
        })
    };

    createProfile = async (event) => {
        event.preventDefault();

        if (this.state.address === '' || !this.state.address) {
            let showError = this.state.showError;
            showError['address'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.phone_number === '' || !this.state.phone_number) {
            let showError = this.state.showError;
            showError['phone_number'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.companyIndustry === '' || !this.state.companyIndustry) {
            let showError = this.state.showError;
            showError['companyIndustry'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.companyValues === '' || !this.state.companyValues) {
            let showError = this.state.showError;
            showError['companyValues'] = true;
            this.setState({
                showError: showError
            })
        }
        let flag = false;
        for (let key in this.state.showError) {
            if (this.state.showError.hasOwnProperty(key) && this.state.showError[key] === true)
                flag = true;
        }
        if (!flag) {
            const {userName, userLastName, userEmail, companyRecruiter, username_} = this.props.dataReducer;
            const attachmentImg = this.state.imgFile ? await this.s3Upload(this.state.imgFile, username_) : null;

            let data = {
                username: username_,
                name: userName,
                lastName: userLastName,
                email: userEmail,
                applicant: false,
                companyName: companyRecruiter,
                address: this.state.address,
                phone_number: this.state.phone_number,
                linkedIn: this.state.linkedIn,
                website: this.state.website,
                facebook: this.state.facebook,
                twitter: this.state.twitter,
                companyIndustry: this.state.companyIndustry,
                companyValues: this.state.companyValues,
                companyBenefits: this.state.companyBenefits,
                imageUrl: attachmentImg
            };
            let enteredInfo = true;
            this.props.dispatch({
                type: 'SET_PROFILE_INFO',
                enteredInfo,
                data
            });
            if (this.props.location.pathname === '/profile-info')
                this.props.history.push("/");
        }
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };

    async s3Upload(file, username) {
        const filename = "profile-pic-"+username;
        const stored = await Storage.vault.put(filename, file, {
            contentType: file.type,
        });
        console.log("--------------stored result", stored);
        return stored.key;
    }

    render() {
        const { userName, userLastName, userEmail, companyRecruiter } = this.props.dataReducer;
        return (
            <main>
                <header className="header-info">
                    <div>
                        <Link to={"/"}><img src={require('../../assets/images/long-logo.svg')} alt="" className="human1"/></Link>
                        <h1>Create your profile and land your dream job</h1>
                    </div>
                </header>
                <section className="section-form-info">
                    <form>
                        <span>*Required fields</span>
                        <h4>Personal Information</h4>
                        <div className="input-wrap">
                            <div className="text-input first-child disabled">
                                <label>Name</label>
                                <input type="text" defaultValue={userName} disabled={true}/>
                            </div>
                            <div className="text-input disabled">
                                <label>Last Name</label>
                                <input type="text" defaultValue={userLastName} disabled={true}/>
                            </div>
                        </div>
                        <div className="text-input disabled">
                            <label>Email</label>
                            <input type="text" defaultValue={userEmail} disabled={true}/>
                        </div>
                        <h4>Company Information</h4>
                        <div className="text-input disabled">
                            <label>Name</label>
                            <input type="text" defaultValue={companyRecruiter} disabled={true}/>
                        </div>
                        <div className="text-input">
                            <label>Address*</label>
                            <input type="text" onChange={this.onInputAddress}/>
                            <label hidden={!this.state.showError['address']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input">
                            <label>Phone*</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <button className="btn btn-outline-secondary custom-class" type="button" onClick={() => {this.setState({dropdownShow: !this.state.dropdownShow})}}
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={"https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png"} className="flag_img"
                                             alt="United States" itemProp="image" /> <span>+1</span>
                                    </button>
                                </div>
                                <input type="number" className="form-control" onChange={this.onInputPhone}/>
                            </div>
                            <label hidden={!this.state.showError['phone_number']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input">
                            <label>LinkedIn</label>
                            <input type="text" onChange={this.onInputLinkedIn} />
                        </div>
                        <div className="text-input">
                            <label>Website</label>
                            <input type="text" onChange={this.onInputWeb} />
                        </div>
                        <div className="text-input">
                            <label>Facebook</label>
                            <input type="text" onChange={this.onInputFb}/>
                        </div>
                        <div className="text-input">
                            <label>Twitter</label>
                            <input type="text" onChange={this.onInputTwitter}/>
                        </div>
                        <h4>Company Details</h4>
                        <div className="text-input dropdown-input">
                            <label>Company Industry*</label>
                            <div className="dropdown-wrap">
                                <input type="text" list="cities" className="type-dropdown" value={this.state.companyIndustry || ''}
                                       onChange={this.dropdownChooseIndustry}
                                       aria-describedby="basic-addon1"/>
                                <datalist id="cities">
                                    {
                                        industryList.map((item, index) => {
                                            return <option key={index}>{item}</option>
                                        })
                                    }
                                </datalist>
                            </div>
                            <label hidden={!this.state.showError['companyIndustry']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input">
                            <label>Company Values and Culture*</label>
                            <textarea onChange={this.onInputValues} />
                            <label hidden={!this.state.showError['companyValues']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input">
                            <label>Company Benefits (Healthcare, 401k)</label>
                            <textarea onChange={this.onInputBenefits} />
                        </div>
                        <div className="photo-input">
                            <label>Photo</label>
                            <div className="upload-wrap">
                                <div hidden={this.state.showImage}><label htmlFor="file-input" className="file-upload">Upload an image</label></div>
                                <input type="file" id="file-input" hidden={true} onChange={this.setFile} />
                                <div className="img-wrap">
                                    <img id="target" className="file-img" src={this.state.image} hidden={!this.state.showImage} alt=""/>
                                    <FontAwesomeIcon className="icon-close" onClick={this.removeImg} icon={faTimesCircle} hidden={this.state.image===null} />
                                </div>
                            </div>
                        </div>


                        <button className="submit-btn" onClick={this.createProfile}>Create the profile</button>
                    </form>
                </section>
                <Footer />
            </main>
        )
    }
}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer
});

export default connect(mapStateToProps)(withRouter(ProfileInfoRecruiter))
