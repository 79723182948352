import React from 'react'
import { connect } from 'react-redux'
import '../../styles/style.scss';
import {
    faAngleDown,
    faPlus,
    faEye,
    faMinus,
    faArrowDown,
    faArrowUp,
    faTimes,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {locationList, skillsList} from "../../assets/libs";
import {S3Image} from "aws-amplify-react";

class PostedJobsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownSort: false,
            dropdownFilter: {},
            activeTab: 'activeJobs',
            showMoreInfo: [],
            activeTabMoreInfo: 'all',
            jobs: [],

            openFilterLocation: false,
            filterLocations: [],
            dropdownShowLocations: false,

            indexFilter: null,
            filterApplied: false,

            openFilterSkills: false,
            filterSkills: [],
            dropdownShowSkills: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props!==prevProps) {
            const { jobs } = this.props.dataReducer;
            const copyJobs = JSON.parse(JSON.stringify(jobs));
            this.setState({
                jobs: copyJobs
            })
        }
    }

    componentDidMount() {
        const { loadedJobs, jobs } = this.props.dataReducer;
        if(!loadedJobs) {
            this.props.dispatch({
                type: "GET_JOBS"
            })
        }
        this.setState({
            jobs: jobs
        });
    }

    showSort = () => {
        this.setState({
            dropdownSort: !this.state.dropdownSort
        })
    };

    sortJobs = (value) => {
        let jobs = this.state.jobs;
        this.setState({
            dropdownSort: !this.state.dropdownSort
        });
        if(value === 'date-decreasing') {
            jobs = jobs.sort( (a, b) => {
                if (a.datePosted > b.datePosted) {
                    return -1;
                }
                if (a.datePosted < b.datePosted) {
                    return 1;
                }
                return 0;
            });
        }
        else if (value === 'date-increasing') {
            jobs = jobs.sort( (a, b) => {
                if (a.datePosted < b.datePosted) {
                    return -1;
                }
                if (a.datePosted > b.datePosted) {
                    return 1;
                }
                return 0;
            });
        }
        else if (value === 'role-increasing') {
            jobs = jobs.sort( (a, b) => {
                if (a.role > b.role) {
                    return -1;
                }
                if (a.role < b.role) {
                    return 1;
                }
                return 0;
            });
        }
        else if (value === 'role-decreasing') {
            jobs = jobs.sort( (a, b) => {
                if (a.role < b.role) {
                    return -1;
                }
                if (a.role > b.role) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState({
            jobs: jobs
        })
    };

    showFilter = (index) => {
        let showFilter = this.state.dropdownFilter;
        showFilter[index] = !this.state.dropdownFilter[index];
        this.setState({
            dropdownFilter: showFilter,
            indexFilter: index
        })
    };

    filterApplicants = (value, index) => {
        let showFilter = this.state.dropdownFilter;
        showFilter[index] = !this.state.dropdownFilter[index];
        this.setState({
            dropdownFilter: showFilter
        });
        if(value==='location') {
            this.setState({
                openFilterLocation: true
            })
        }
        else if(value==='skills') {
            this.setState({
                openFilterSkills: true
            })
        }
    };

    changeTab = (activeTab) => {
        this.setState({
            activeTab: activeTab
        })
    };

    changeTabMoreInfo = (activeTab, index) => {
        if(activeTab === 'matched') {
            let job = this.state.jobs[index];
            let applicants = job.applicants;
            applicants = applicants.sort((a, b) => {
                if (a.match < b.match) {
                    return -1;
                }
                if (a.match > b.match) {
                    return 1;
                }
                return 0;
            });
            applicants = applicants.filter(app => app.match >= 95);
            let jobs = this.state.jobs;
            jobs[index].applicants = applicants;
            this.setState({
                activeTabMoreInfo: activeTab,
                jobs: jobs
            })
        }
        else if (activeTab === 'all') {
            const { jobs } = this.props.dataReducer;
            const copyJobs = JSON.parse(JSON.stringify(jobs));
            this.setState({
                activeTabMoreInfo: activeTab,
                jobs: copyJobs
            })
        }
    };

    showMoreInfo = (idx) => {
        let moreInfo = this.state.showMoreInfo;
        const { jobs } = this.props.dataReducer;
        const copyJobs = JSON.parse(JSON.stringify(jobs));
        copyJobs.forEach((job, index) => {
            if(index!==idx) {
                moreInfo[index] = false;
            }
        });
        moreInfo[idx] = !this.state.showMoreInfo[idx];
        this.setState({
            showMoreInfo: moreInfo,
            activeTabMoreInfo: "all"
        });
        let width = window.innerWidth;
        if(moreInfo[idx] && width > 500) {
            if(idx !== 0) {
                let element = document.getElementById("job" + (idx - 1));
                element.scrollIntoView({behavior: 'smooth'});
            }
        }
    };

    removeFilters = () => {
        const { jobs } = this.props.dataReducer;
        const copyJobs = JSON.parse(JSON.stringify(jobs));
        this.setState({
            jobs: copyJobs,
            filterApplied: false
        });
    };

    applyLocationFilter = () => {
        this.setState({
            openFilterLocation: false
        });
        const { jobs } = this.props.dataReducer;
        const copyJobs = JSON.parse(JSON.stringify(jobs));
        let applicants = copyJobs[this.state.indexFilter].applicants;
        let filtered = [];
        if (this.state.filterLocations.length >= 1) {
            for(let loc of this.state.filterLocations) {
                filtered = filtered.concat(applicants.filter(applicant => applicant.location.includes(loc)));
            }
        }
        copyJobs[this.state.indexFilter].applicants = filtered;
        this.setState({
            jobs: copyJobs,
            filterApplied: true,
        });
    };

    applySkillFilter = () => {
        this.setState({
            openFilterSkills: false
        });
        const { jobs } = this.props.dataReducer;
        const copyJobs = JSON.parse(JSON.stringify(jobs));
        let applicants = copyJobs[this.state.indexFilter].applicants;
        let filtered = [];
        if (this.state.filterSkills.length >= 1) {
            for(let skill of this.state.filterSkills) {
                filtered = filtered.concat(applicants.filter(applicant => applicant.skills.includes(skill)));
            }
        }
        copyJobs[this.state.indexFilter].applicants = filtered;
        this.setState({
            jobs: copyJobs,
            filterApplied: true,
        });
    };

    chooseLocation = (event) => {
        let locations = this.state.filterLocations;
        if(!locations.includes(event.target.value)) {
            locations = locations.concat(event.target.value);
        }
        this.setState({
            filterLocations: locations,
            dropdownShowLocations: false
        })
    };
    chooseSkill = (event) => {
        let skills = this.state.filterSkills;
        if(!skills.includes(event.target.value)) {
            skills = skills.concat(event.target.value);
        }
        this.setState({
            filterSkills: skills,
            dropdownShowSkills: false
        })
    };

    removeLocation = (index) => {
        const idx = index;
        this.setState({
            filterLocations: this.state.filterLocations.filter((location, index) => index !== idx)
        });
    };

    removeSkill = (index) => {
        const idx = index;
        this.setState({
            filterSkills: this.state.filterSkills.filter((skill, index) => index !== idx)
        });
    };

    render() {
        let width = window.innerWidth;
        const {jobs} = this.props.dataReducer;
        const { userName, userLastName, userProfileInfo, username_ } = this.props.dataReducer;
        window.onclick = (event) => {
            if (event.target.id !== "sort-icon" && event.target.id !== "sort-btn") {
                this.setState({
                    dropdownSort: false
                });
            }
            if (event.target.id !== "filter-icon" && event.target.id !== "filter-btn") {
                this.setState({
                    dropdownFilter: {}
                });
            }
        };
        if(!jobs) {
            return <div className='spinner-wrapper'>
                <span className='spinner' />
            </div>
        }
        return (
            <main>
                {
                    this.state.openFilterLocation &&
                        <div className="container-filter">
                            <div className="container1">
                                <div className="section-wrapper">
                                    <h5>Filters</h5>
                                    <FontAwesomeIcon className="icon cursor-pointer" onClick={()=>{this.setState({openFilterLocation: false})}} icon={faTimes} />
                                </div>
                                <span>By Location:</span>
                                <div className="text-input dropdown-input">
                                    <div className="dropdown-wrap">
                                        <input type="dropdown" onClick={() => {this.setState({dropdownShowLocations: !this.state.dropdownShowLocations})}} value={"Choose location"} readOnly={true}/>
                                        <FontAwesomeIcon className="icon-arrow-down" onClick={() => {this.setState({dropdownShowLocations: !this.state.dropdownShowLocations})}} icon={faAngleDown} />
                                    </div>
                                    <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowLocations}>
                                        {
                                            locationList.map((type, index) => {
                                                return <button className="dropdown-item" key={index} onClick={this.chooseLocation} value={type}>{type}</button>
                                            })
                                        }
                                    </div>
                                </div>
                                <span hidden={this.state.filterLocations.length === 0}>Chosen locations:</span>
                                <div className="skills">
                                    {
                                        this.state.filterLocations.map((location, index) => {
                                            return <div className="skill" key={index} >{location} <FontAwesomeIcon className="icon-close" onClick={() => this.removeLocation(index)} icon={faTimes} /></div>
                                        })
                                    }
                                </div>
                                <div className="btn-filter cursor-pointer" onClick={this.applyLocationFilter} >
                                    APPLY FILTER
                                </div>
                            </div>
                        </div>
                }
                {
                    this.state.openFilterSkills &&
                    <div className="container-filter">
                        <div className="container1">
                            <div className="section-wrapper">
                                <h5>Filter</h5>
                                <FontAwesomeIcon className="icon cursor-pointer" onClick={()=>{this.setState({openFilterSkills: false})}} icon={faTimes} />
                            </div>
                            <span>By Location:</span>
                            <div className="text-input dropdown-input">
                                <div className="dropdown-wrap">
                                    <input type="dropdown" onClick={() => {this.setState({dropdownShowSkills: !this.state.dropdownShowSkills})}} value={"Choose skill"} readOnly={true}/>
                                    <FontAwesomeIcon className="icon-arrow-down" onClick={() => {this.setState({dropdownShowSkills: !this.state.dropdownShowSkills})}} icon={faAngleDown} />
                                </div>
                                <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowSkills}>
                                    {
                                        skillsList.map((type, index) => {
                                            return <button className="dropdown-item" key={index} onClick={this.chooseSkill} value={type}>{type}</button>
                                        })
                                    }
                                </div>
                            </div>
                            <span hidden={this.state.filterSkills.length === 0}>Chosen locations:</span>
                            <div className="skills">
                                {
                                    this.state.filterSkills.map((skill, index) => {
                                        return <div className="skill" key={index} >{skill} <FontAwesomeIcon className="icon-close" onClick={() => this.removeSkill(index)} icon={faTimes} /></div>
                                    })
                                }
                            </div>
                            <div className="btn-filter cursor-pointer" onClick={this.applySkillFilter} >
                                APPLY FILTER
                            </div>
                        </div>
                    </div>
                }
                <div className="section-above">
                    <div className="profile-img" hidden={width>500}>
                        { userProfileInfo.imageUrl ?
                            <S3Image
                                imgKey={"profile-pic-" + username_}
                                alt="user avatar"
                            /> :
                            <img src={require('../../assets/images/no-profile-pic.jpg')} alt="" />
                        }
                        <p>{userName + " " + userLastName}</p>
                    </div>
                    <div className="share">
                        <img src={require('../../assets/images/plus-icon.svg')} alt="" />
                        <label>Post a new job</label>
                    </div>
                </div>
                <section className="section-wrap-jobs">
                    <div className="sorting-wrap">
                        <div className="btn-wrap">
                            <div className={this.state.activeTab === 'activeJobs'? "btn-accent btn-mobile active" : "btn-accent btn-mobile"} onClick={() => this.changeTab('activeJobs')}>Active Jobs</div>
                            <div className={this.state.activeTab === 'historyJobs'? "btn-accent longer active" : "btn-accent longer"} onClick={() => this.changeTab('historyJobs')}>History of Posted Jobs</div>
                        </div>
                        <div className="dropdown-wrapper">
                            <div className="dropdown-btn">
                                <label id="sort-btn" onClick={this.showSort}>Sorting</label>
                                <FontAwesomeIcon id="sort-icon" className="icon-arrow-down" onClick={this.showSort} icon={faAngleDown} />
                            </div>
                            <div className="dropdown-menu-custom" hidden={!this.state.dropdownSort}>
                                <button className="dropdown-item" onClick={() => this.sortJobs('date-decreasing')}>By date posted <FontAwesomeIcon className="icon-arrow-down" onClick={this.showSort} icon={faArrowUp} /></button>
                                <button className="dropdown-item" onClick={() => this.sortJobs('date-increasing')}>By date posted <FontAwesomeIcon className="icon-arrow-down" onClick={this.showSort} icon={faArrowDown} /></button>
                                <button className="dropdown-item" onClick={() => this.sortJobs('role-decreasing')}>By role (A-Z)</button>
                                <button className="dropdown-item" onClick={() => this.sortJobs('role-increasing')}>By role (Z-A)</button>
                            </div>
                        </div>
                    </div>
                    <div className="list-wrap">
                        { this.state.jobs && this.state.jobs.map((job, index) => {
                            return <div className="item-wrapper" key={index}>
                                    <div className={"item-wrap" + (this.state.showMoreInfo[index] ? " bottom-border": "")} id={"job"+index}>
                                        <div className="item first">
                                            <label>Role</label>
                                            <span>{job.role}</span>
                                        </div>
                                        <div className="item">
                                            <label>Job Type</label>
                                            <span>{job.jobType}</span>
                                        </div>
                                        <div className="item">
                                            <label>Location</label>
                                            <span>{job.location}</span>
                                        </div>
                                        <div className="item">
                                            <label>Status</label>
                                            <span>{job.status}</span>
                                        </div>
                                        {   this.state.showMoreInfo[index] ?
                                            <div className="item-more-info">
                                                <FontAwesomeIcon className="icon" onClick={() => this.showMoreInfo(index)}
                                                                 icon={faMinus}/>
                                                <span onClick={() => this.showMoreInfo(index)}>Less info</span>
                                            </div>
                                            :
                                            <div className="item-more-info">
                                                <FontAwesomeIcon className="icon" onClick={() => this.showMoreInfo(index)}
                                                                 icon={faPlus}/>
                                                <span onClick={() => this.showMoreInfo(index)}>More info</span>
                                            </div>
                                        }
                                    </div>
                                        <div className="more-info-wrapper" hidden={!this.state.showMoreInfo[index]}>
                                            <div className="sorting-wrap">
                                                <div className="btn-wrap">
                                                    <div className={this.state.activeTabMoreInfo === 'all'? "btn-accent active" : "btn-accent"} onClick={() => this.changeTabMoreInfo('all', index)}>All Applicants</div>
                                                    <div className={this.state.activeTabMoreInfo === 'matched'? "btn-accent longer active" : "btn-accent longer"} onClick={() => this.changeTabMoreInfo('matched', index)}>Matched Applicants</div>
                                                </div>
                                                <div className="dropdown-wrapper">
                                                    <div className="dropdown-btn">
                                                        <label onClick={()=>this.showFilter(index)} id="filter-btn">Filter</label>
                                                        <FontAwesomeIcon className="icon-arrow-down" id="filter-icon" onClick={()=>this.showFilter(index)} icon={faAngleDown} />
                                                        { this.state.filterApplied &&
                                                            <FontAwesomeIcon className="icon cursor-pointer"
                                                                             onClick={this.removeFilters} icon={faTimesCircle}/>
                                                        }
                                                    </div>
                                                    <div className="dropdown-menu-custom" hidden={!this.state.dropdownFilter[index]}>
                                                        <button className="dropdown-item" onClick={()=>this.filterApplicants('location', index)}>Location</button>
                                                        <button className="dropdown-item" onClick={()=>this.filterApplicants('skills', index)}>Skills</button>
                                                    </div>
                                                </div>
                                            </div>
                                            { job.applicants.length === 0 && this.state.activeTabMoreInfo === 'matched' ?
                                                <label className="no-matching">There are no applicants with 95% matching or higher.</label>
                                                :
                                            <table className="list-app-wrap">
                                                <thead>
                                                    <tr>
                                                        <th>NMB</th>
                                                        <th>Matching (%)</th>
                                                        <th>Name</th>
                                                        <th>View Profile</th>
                                                        <th>Send Invitation</th>
                                                        <th>Save Candidate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                { job.applicants.map((applicant, index) => {
                                                    return <tr key={index}>
                                                                <td className="mobile-padding"><span>NMB</span>{index + 1}</td>
                                                                <td><span>Matching (%)</span>{applicant.match + "%"}</td>
                                                                <td><span>Name</span>{applicant.name}</td>
                                                                <td className="mobile-border">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="#191847"/>
                                                                    </svg>
                                                                    <span>View Profile</span>
                                                                </td>
                                                                <td className="mobile-border">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M4.01 6.03L11.52 9.25L4 8.25L4.01 6.03ZM11.51 14.75L4 17.97V15.75L11.51 14.75ZM2.01 3L2 10L17 12L2 14L2.01 21L23 12L2.01 3Z" fill="#191847"/>
                                                                    </svg>
                                                                    <span>Send Invitation</span>
                                                                </td>
                                                                <td className="mobile-border">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3ZM17 18L12 15.82L7 18V5H17V18Z" fill="#191847"/>
                                                                    </svg>
                                                                    <span>Save Candidate</span>
                                                                </td>
                                                        </tr>
                                                })
                                                }
                                                </tbody>
                                            </table>
                                            }
                                        </div>
                                    </div>
                        })
                        }
                    </div>
                </section>

            </main>
        )
    }


}


const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(PostedJobsView);
