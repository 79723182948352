import React from 'react'
import { connect } from 'react-redux'
import '../../styles/style.scss';
import { withRouter } from "react-router-dom";

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recruiter: false,
            applicant: true,
            name: null,
            lastName: null,
            emailReg: null,
            passReg: null,
            emailSignIn: null,
            passSignIn: null,
            acceptPrivacy: false,
            signin: window.localStorage.getItem('signIn') === "signIn",
            role: 'applicant',
            error: false,
            errorMsg: '',
            errorPolicy: false,
            errorMsgPolicy: '',
            privacyPolicy: false,
            company: null
        };
    }

    handleAuth = () => {
        const role = this.state.role;
        const name = this.state.name;
        const lastName = this.state.lastName;
        const company = this.state.company;
        if (!this.state.signin) {
            const login = this.state.emailReg;
            const password = this.state.passReg;
            if (!role || !name || !lastName || !password || !login || !this.state.privacyPolicy) {
                if (!this.state.privacyPolicy) {
                    this.setState({
                        errorPolicy: true,
                        errorMsgPolicy: 'Please accept our privacy policy before you register.'
                    })
                }
                if (!role || !name || !lastName || !password || !login) {
                    this.setState({
                        error: true,
                        errorMsg: 'Please fill in the required fields.'
                    })
                }
            }
            else {
                this.props.dispatch({
                    type: 'AUTH_REQUEST_SIGNUP',
                    payload: {login, password, name, lastName, role, company}
                });
            }
        } else {
            const login = this.state.emailSignIn;
            const password = this.state.passSignIn;
            if(!password || !login) {
                this.setState({
                    error: true,
                    errorMsg: 'Please fill in the required fields.'
                })
            }
            else {
                this.props.dispatch({
                    type: 'AUTH_REQUEST_SIGNIN',
                    payload: {login, password}
                })
            }
        }

    };

    applicantClick = () => {
        this.setState({
            recruiter: false,
            applicant: !this.state.applicant,
            role: 'applicant'
        });
        let userType = 'applicant';
        this.props.dispatch({
            type: 'PUT_USER_TYPE',
            userType
        })
    };

    recruiterClick = () => {
        this.setState({
            applicant: false,
            recruiter: !this.state.recruiter,
            role: 'recruiter'
        });
        let userType = 'recruiter';
        this.props.dispatch({
            type: 'PUT_USER_TYPE',
            userType
        })
    };

    changeToSignIn = () => {
        this.setState({
            signin: !this.state.signin
        });
        window.localStorage.setItem('signIn', !this.state.signin ? "signIn" : "signUp");
    };

    onChangeEmail = (event) => {
        this.setState({
            emailReg: event.target.value,
            error: false
        })
    };

    onChangePass = (event) => {
        this.setState({
            passReg: event.target.value,
            error: false
        })
    };

    onChangeEmailSignIn = (event) => {
        this.setState({
            emailSignIn: event.target.value,
            error: false
        })
    };

    onChangePassSignIn = (event) => {
        this.setState({
            passSignIn: event.target.value,
            error: false
        })
    };

    render() {
        const { error, errorSignIn } = this.props.authReducer;
        return (
            <div className={"registration-form" + (this.state.signin ? " form-margintop" : "")}>
                {   !this.state.signin &&
                    <div>
                        <p>Get started</p>
                        <div className="radio-input">
                            <input type="radio" onChange={this.applicantClick} checked={this.state.applicant} />
                            <label>I am a job applicant</label>
                        </div>
                        <div className="radio-input">
                            <input type="radio" onChange={this.recruiterClick} checked={this.state.recruiter} />
                            <label>I am a recruiter</label>
                        </div>
                        <div className="text-input">
                            <label>Name*</label>
                            <input type="text" onChange={(event) => { this.setState({name: event.target.value, error: false}) }}/>
                        </div>
                        <div className="text-input">
                            <label>Last name*</label>
                            <input type="text" onChange={(event) => { this.setState({lastName: event.target.value, error: false}) }}/>
                        </div>
                        {   this.state.recruiter &&
                            <div className="text-input">
                                <label>Company*</label>
                                <input type="text" onChange={(event) => { this.setState({company: event.target.value, error: false}) }}/>
                            </div>
                        }
                        <div className="text-input">
                            <label>Email*</label>
                            <input type="text" onChange={this.onChangeEmail}/>
                        </div>
                        <div className="text-input">
                            <label>Password*</label>
                            <input type="password" onChange={this.onChangePass}/>
                        </div>
                        <div className="chb-input">
                            <input type="checkbox" onChange={(event) => { this.setState({privacyPolicy: event.target.value, errorPolicy: false});  }} />
                            <label>I accept Konaty's <a href="/">privacy policy</a></label>
                        </div>
                        {
                            this.state.errorPolicy && <label style={{color: 'red', fontSize: 14+'px'}}> {this.state.errorMsgPolicy}</label>
                        }
                        {
                            this.state.error && <label style={{color: 'red', fontSize: 14+'px'}}> {this.state.errorMsg}</label>
                        }
                        {
                            error && <label style={{color: 'red', fontSize: 14+'px'}}> {error}</label>
                        }
                    </div>
                }
                {   this.state.signin &&
                    <div>
                        <p>Sign in</p>
                        <div className="text-input">
                            <label>Email</label>
                            <input type="text" onChange={this.onChangeEmailSignIn}/>
                        </div>
                        <div className="text-input">
                            <label>Password</label>
                            <input type="password" onChange={this.onChangePassSignIn}/>
                        </div>
                        {
                            errorSignIn && <label style={{color: 'red', fontSize: 14+'px'}}> {errorSignIn}</label>
                        }
                    </div>
                }
                <div className="submit-btn" onClick={this.handleAuth}>{this.state.signin ? 'Sign in': 'Register'}</div>
                <p className="signin-txt">{this.state.signin ? "Don't have an account?" : "Already have an account?"} <span className="sign-in" onClick={this.changeToSignIn}>{this.state.signin ? "Register." : " Sign in."}</span></p>
            </div>
        )
    }

}


const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(withRouter(RegisterForm));
