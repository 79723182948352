import React from 'react'
import { connect } from 'react-redux'
import '../../styles/style.scss';
import {faPencilAlt, faTimes, faSave, faAngleDown, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {experienceTypeList, degreesList, experienceLevelList, jobTypeList, industryList, bSizeList, rolesList, skillsList} from '../../assets/libs';
import { withRouter} from "react-router";
import {S3Image} from "aws-amplify-react";

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editingRoles: false,
            editingPersonalInfo: false,
            editingSummary: false,
            editingExperience: false,
            editingEducation: false,
            editingJobInterests: false,

            dropdownShow: false,
            dropdownShowType: false,
            dropdownShowJob: false,
            dropdownShowLevel: false,
            dropdownShowIndustry: false,
            dropdownShowBSize: false,
            dropdownShowRoles: false,
            dropdownShowSkills: false,
            dropdownShowDegree: false,

            name: null,
            lastName: null,
            email: null,
            phone_number: null,
            linkedIn: null,
            about: null,
            experiences: [],
            educations: [],
            jobTypeInterest: null,
            experienceLevel: null,
            industry: null,
            businessSize: null,
            rolesInterest: [],
            skills: [],
            image: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            const { userName, userEmail, userProfileInfo } = this.props.dataReducer;
            if(!!userProfileInfo) {
                this.setState({
                    name: userName,
                    lastName: userProfileInfo.lastName,
                    email: userEmail,
                    phone_number: userProfileInfo.phone_number,
                    linkedIn: userProfileInfo.linkedIn,
                    about: userProfileInfo.about,
                    experiences: userProfileInfo.experiences,
                    educations: userProfileInfo.educations,
                    jobTypeInterest: userProfileInfo.jobTypeInterest,
                    experienceLevel: userProfileInfo.experienceLevel,
                    industry: userProfileInfo.industry,
                    businessSize: userProfileInfo.businessSize,
                    rolesInterest: userProfileInfo.rolesInterest,
                    skills: userProfileInfo.skills,
                    image: userProfileInfo.imageUrl
                })
            }
            // if(!userProfileInfo && loaded) {
            //     this.props.history.push('/profile-info');
            // }
        }
    }

    componentDidMount() {
        const { userName, userEmail, userProfileInfo } = this.props.dataReducer;
        if(!!userProfileInfo) {
            this.setState({
                name: userName,
                lastName: userProfileInfo.lastName,
                email: userEmail,
                phone_number: userProfileInfo.phone_number,
                linkedIn: userProfileInfo.linkedIn,
                about: userProfileInfo.about,
                experiences: userProfileInfo.experiences,
                educations: userProfileInfo.educations,
                jobTypeInterest: userProfileInfo.jobTypeInterest,
                experienceLevel: userProfileInfo.experienceLevel,
                industry: userProfileInfo.industry,
                businessSize: userProfileInfo.businessSize,
                rolesInterest: userProfileInfo.rolesInterest,
                skills: userProfileInfo.skills,
                image: userProfileInfo.imageUrl
            })
        }

    }

    dropdownShowExperienceType = () => {
        this.setState({
            dropdownShowType: !this.state.dropdownShowType
        })
    };

    dropdownShowSkills = () => {
        this.setState({
            dropdownShowSkills: !this.state.dropdownShowSkills
        })
    };

    dropdownShowRoles = () =>  {
        this.setState({
            dropdownShowRoles: !this.state.dropdownShowRoles
        })
    };

    dropdownShowBSize = () =>  {
        this.setState({
            dropdownShowBSize: !this.state.dropdownShowBSize
        })
    };

    dropdownShowIndustry = () =>  {
        this.setState({
            dropdownShowIndustry: !this.state.dropdownShowIndustry
        })
    };

    dropdownShowLevel = () =>  {
        this.setState({
            dropdownShowLevel: !this.state.dropdownShowLevel
        })
    };

    dropdownShowJobType = () =>  {
        this.setState({
            dropdownShowJob: !this.state.dropdownShowJob
        })
    };

    dropdownShowDegree = () =>  {
        this.setState({
            dropdownShowDegree: !this.state.dropdownShowDegree
        })
    };

    dropdownChoose = (event, index) => {
        event.preventDefault();
        let experiences = this.state.experiences;
        experiences[index].type = event.target.value;
        this.setState({
            dropdownShowType: !this.state.dropdownShowType,
            experiences: experiences
        })
    };

    editExperience = (event, index, value) => {
        let experiences = this.state.experiences;
        experiences[index][value] = event.target.value;
        this.setState({
            experiences: experiences
        })
    };

    editEdu = (event, index, value) => {
        let educations = this.state.educations;
        educations[index][value] = event.target.value;
        this.setState({
            educations: educations
        })
    };

    dropdownChooseSkills = (event) => {
        event.preventDefault();
        this.setState({
            dropdownShowSkills: !this.state.dropdownShowSkills
        });
        if(!this.state.skills.includes(event.target.value) && this.state.skills.length < 10) {
            this.setState({
                skills: this.state.skills.concat(event.target.value)
            });
        }
    };

    dropdownChooseRoles = (event) => {
        event.preventDefault();
        this.setState({
            dropdownShowRoles: !this.state.dropdownShowRoles
        });
        if(!this.state.rolesInterest.includes(event.target.value) && this.state.rolesInterest.length < 5) {
            this.setState({
                rolesInterest: this.state.rolesInterest.concat(event.target.value)
            });
        }
    };

    dropdownChooseDegree = (event, index) => {
        event.preventDefault();
        let educations = this.state.educations;
        educations[index].degree = event.target.value;
        this.setState({
            dropdownShowDegree: !this.state.dropdownShowDegree,
            educations: educations,
        })
    };

    removeFromList = (event, index, isRole) => {
        const idx = index;
        if(isRole) {
            this.setState({
                rolesInterest: this.state.rolesInterest.filter((skill, index) => index !== idx)
            });
        } else {
            this.setState({
                skills: this.state.skills.filter((skill, index) => index !== idx)
            });
        }
    };

    updateProfileInfo = () => {
        const data = {
            name: this.state.name,
            lastName: this.state.lastName,
            email: this.state.email,
            applicant: true,
            phone_number: this.state.phone_number,
            linkedIn: this.state.linkedIn,
            about: this.state.about,
            experiences: this.state.experiences,
            educations: this.state.educations,
            jobTypeInterest: this.state.jobTypeInterest,
            experienceLevel: this.state.experienceLevel,
            industry: this.state.industry,
            businessSize: this.state.businessSize,
            rolesInterest: this.state.rolesInterest,
            skills: this.state.skills,
            imageUrl: this.state.image
        };
        this.props.dispatch({
            type: 'UPDATE_USER_DATA',
            data
        });
        this.setState({
            editingRoles: false,
            editingPersonalInfo: false,
            editingSummary: false,
            editingExperience: false,
            editingEducation: false,
            editingJobInterests: false
        })
    };

    changeStartDate = (event) => {
        this.setState({

        })
    };

    getDateValue = (dateToConvert) => {
        let d = new Date(dateToConvert),
            month = '' + (d.getMonth()+1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let date = year + "-" + month + "-" + day;

        return date.toString();
    };

    render() {
        let width = window.innerWidth;
        const { userProfileInfo, loaded, username_, userName, userLastName } = this.props.dataReducer;

        if(!userProfileInfo && !loaded) {
            return <div className='spinner-wrapper'>
                <span className='spinner' />
            </div>
        }

        return (
            <main>
                <div className="section-above">
                    <div className="profile-img" hidden={width>500}>
                        { this.state.image ?
                            <S3Image
                                imgKey={"profile-pic-" + username_}
                                alt="user avatar"
                            /> :
                            <img src={require('../../assets/images/no-profile-pic.jpg')} alt="" />
                        }
                        <p>{(userName || "") + " " +( userLastName || '')}</p>
                    </div>
                    <div className="share">
                        <img src={require('../../assets/images/icon-share.svg')} alt="" />
                        <label>Share profile</label>
                    </div>
                </div>
                <div className="section-wrap">
                    <div className="info-section smaller-section">
                        <div className="section-title">
                            <h6>Personal Information</h6>
                            {
                                this.state.editingPersonalInfo ?
                                    <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                    :
                                    <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingPersonalInfo: true})}} icon={faPencilAlt} />
                            }
                        </div>
                        <div className="info-item">
                            <label>Name:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.name} disabled={true} onChange={(event)=>{this.setState({name: event.target.value})}} /> : <p>{this.state.name}</p> }
                        </div>
                        <div className="info-item">
                            <label>Last Name:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.lastName} onChange={(event)=>{this.setState({lastName: event.target.value})}} /> : <p>{this.state.lastName}</p> }
                        </div>
                        <div className="info-item">
                            <label>Email:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.email} disabled={true} onChange={(event)=>{this.setState({email: event.target.value})}} /> : <p>{this.state.email}</p> }
                        </div>
                        <div className="info-item">
                            <label>Phone:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.phone_number} onChange={(event)=>{this.setState({phone_number: event.target.value})}} /> : <p>{this.state.phone_number}</p> }
                        </div>
                        <div className="info-item">
                            <label>LinkedIn:</label>
                            { this.state.editingPersonalInfo ? <input defaultValue={this.state.linkedIn} onChange={(event)=>{this.setState({linkedIn: event.target.value})}} /> : <p>{this.state.linkedIn}</p> }
                        </div>
                    </div>
                    <div className="info-section smaller-section">
                        <div className="section-title">
                            <h6>Summary</h6>
                            {
                                this.state.editingSummary ?
                                    <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                    :
                                    <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingSummary: true})}} icon={faPencilAlt} />
                            }
                        </div>
                        <div className="info-item">
                            <label>About:</label>
                            { this.state.editingSummary ? <textarea defaultValue={this.state.about} onChange={(event)=>{this.setState({about: event.target.value})}} /> : <p>{this.state.about}</p> }
                        </div>
                    </div>
                </div>
                <div className="info-section divider">
                    <div className="section-title">
                        <h6>Experience</h6>
                        {
                            this.state.editingExperience ?
                                <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                :
                                <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingExperience: true})}} icon={faPencilAlt} />
                        }
                    </div>
                    {   this.state.experiences && this.state.experiences.map((experience, index) => {
                        return <div key={index} style={index>0 ? {marginTop: 1 + "rem"} : null}>
                            {
                                this.state.editingExperience ?
                                    <div className="input-date-wrap">
                                        <div className="text-input first-child">
                                            Start Date:
                                            <input type="date" defaultValue={this.getDateValue(experience.startDate)} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.editExperience(event, index, 'startDate')} />
                                        </div>  <hr />
                                        <div className="text-input">
                                            End Date:
                                            <input type="date" defaultValue={experience.endDate ? this.getDateValue(experience.endDate) : ""} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.editExperience(event, index, 'endDate')}/>
                                        </div>
                                    </div>
                                    :
                                    <h5>{(new Date(experience.startDate).getMonth()+1) + '.' + new Date(experience.startDate).getFullYear()} - {experience.currentlyWorking ? "current" : (new Date(experience.endDate).getMonth() +1) + '.' + new Date(experience.endDate).getFullYear()}</h5>
                            }
                            <div className="info-item">
                                <label>Title:</label>
                                {this.state.editingExperience ? <input defaultValue={experience.title} onChange={(event) => this.editExperience(event, index, 'title')} /> :
                                    <p>{experience.title}</p>}
                            </div>
                            <div className="info-item">
                                <label>Experience Type:</label>
                                {this.state.editingExperience ?

                                    <div className="text-input dropdown-input">
                                        <div className="dropdown-wrap">
                                            <input type="dropdown" onClick={this.dropdownShowExperienceType} value={experience.type} readOnly={true} />
                                            <FontAwesomeIcon className="icon-arrow-down" icon={faAngleDown} onClick={this.dropdownShowExperienceType} />
                                        </div>
                                        <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowType}>
                                            {
                                                experienceTypeList && experienceTypeList.map((type, id) => {
                                                    return <button className="dropdown-item" key={id} onClick={(event) => this.dropdownChoose(event, index)} value={type}>{type}</button>
                                                })
                                            }
                                        </div>
                                    </div>

                                    : <p>{experience.type}</p>}
                            </div>
                            <div className="info-item">
                                <label>Company:</label>
                                {this.state.editingExperience ?
                                    <input defaultValue={experience.company} onChange={(event) => this.editExperience(event, index, 'company')} />
                                    : <p>{experience.company}</p>}
                            </div>
                            <div className="info-item">
                                <label>Location:</label>
                                {this.state.editingExperience ? <input defaultValue={experience.location} onChange={(event) => this.editExperience(event, index, 'location')}/> :
                                    <p>{experience.location}</p>}
                            </div>
                            <div className="info-item">
                                <label>Description:</label>
                                {this.state.editingExperience ? <textarea
                                        defaultValue={experience.description} onChange={(event) => this.editExperience(event, index, 'description')} /> :
                                    <p>{experience.description}</p>}
                            </div>
                        </div>
                    })
                    }
                    {   this.state.editingExperience &&
                    <div className="add-wrapper">
                        <FontAwesomeIcon className="icon-plus" icon={faPlusCircle} />
                        <label>Add new experience</label>
                    </div>
                    }
                </div>
                <div className="info-section">
                    <div className="section-title">
                        <h6>Education</h6>
                        {
                            this.state.editingEducation ?
                                <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                :
                                <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingEducation: true})}} icon={faPencilAlt} />
                        }
                    </div>
                    {   this.state.educations && this.state.educations.map ((edu, index) => {
                        return <div key={index} style={index>0 ? {marginTop: 1 + "rem"} : null}>
                            {
                                this.state.editingEducation ?
                                    <div className="input-date-wrap">
                                        <div className="text-input first-child">
                                            Start Date:
                                            <input type="date" defaultValue={this.getDateValue(edu.startDate)} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.editEdu(event, index, 'startDate')} />
                                        </div> <hr />
                                        <div className="text-input">
                                            End Date:
                                            <input type="date" defaultValue={edu.endDate ? this.getDateValue(edu.endDate) : ""} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.editEdu(event, index, 'endDate')}/>
                                        </div>
                                    </div>
                                    :
                                    <h5>{(new Date(edu.startDate).getMonth()+1) + '.' + new Date(edu.startDate).getFullYear()} - {edu.currentlyStudying ? "current" : (new Date(edu.endDate).getMonth()+1) + '.' + new Date(edu.endDate).getFullYear()}</h5>
                            }
                            <div className="info-item">
                                <label>School:</label>
                                {this.state.editingEducation ?
                                    <input defaultValue={edu.school} onChange={(event) => this.editEdu(event, index, 'school')} />
                                    :
                                    <p>{edu.school}</p>}
                            </div>
                            <div className="info-item">
                                <label>Degree:</label>
                                {this.state.editingEducation ?
                                    <div className="text-input dropdown-input">
                                        <div className="dropdown-wrap">
                                            <input type="dropdown" onClick={this.dropdownShowDegree} defaultValue={edu.degree} />
                                            <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowDegree} icon={faAngleDown} />
                                        </div>
                                        <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowDegree}>
                                            {
                                                degreesList && degreesList.map((type, id) => {
                                                    return <button className="dropdown-item" key={id} onClick={(event) => this.dropdownChooseDegree(event, index)} value={type}>{type}</button>
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <p>{edu.degree ? edu.degree : '/'}</p>}
                            </div>
                            <div className="info-item">
                                <label>Field of Study:</label>
                                {this.state.editingEducation ? <input defaultValue={edu.field} onChange={(event) => this.editEdu(event, index, 'field')}/>
                                : <p>{edu.field}</p>}
                            </div>
                            <div className="info-item">
                                <label>GPA:</label>
                                {this.state.editingEducation ? <input defaultValue={edu.gpa} onChange={(event) => this.editEdu(event, index, 'gpa')}/> : <p>{edu.gpa}</p>}
                            </div>
                        </div>
                    })
                    }
                    {   this.state.editingEducation &&
                    <div className="add-wrapper">
                        <FontAwesomeIcon className="icon-plus" icon={faPlusCircle} />
                        <label>Add new education</label>
                    </div>
                    }
                </div>
                <div className="section-wrap">
                    <div className="info-section smaller-section">
                        <div className="section-title">
                            <h6>Job Interests</h6>
                            {
                                this.state.editingJobInterests ?
                                    <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                    :
                                    <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingJobInterests: true})}} icon={faPencilAlt} />
                            }
                        </div>
                        <div className="info-item">
                            <label>Job Type:</label>
                            { this.state.editingJobInterests ?
                                <div className="text-input dropdown-input">
                                    <div className="dropdown-wrap">
                                        <input type="dropdown" onClick={this.dropdownShowJobType} value={this.state.jobTypeInterest} />
                                        <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowJobType} icon={faAngleDown} />
                                    </div>
                                    <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowJob}>
                                        {
                                            jobTypeList && jobTypeList.map((type, index) => {
                                                return <button className="dropdown-item" key={index} onClick={(event)=>{this.setState({dropdownShowJob: !this.state.dropdownShowJob, jobTypeInterest: event.target.value})}}value={type}>{type}</button>
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                <p>{this.state.jobTypeInterest}</p> }
                        </div>
                        <div className="info-item">
                            <label>Experience Level:</label>
                            { this.state.editingJobInterests ?
                                <div className="text-input dropdown-input">
                                    <div className="dropdown-wrap">
                                        <input type="dropdown" onClick={this.dropdownShowLevel} value={this.state.experienceLevel}/>
                                        <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowLevel} icon={faAngleDown} />
                                    </div>
                                    <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowLevel}>
                                        {
                                            experienceLevelList && experienceLevelList.map((type, index) => {
                                                return <button className="dropdown-item" key={index} onClick={(event)=>{this.setState({dropdownShowLevel: !this.state.dropdownShowLevel, experienceLevel: event.target.value})}} value={type}>{type}</button>
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                <p>{this.state.experienceLevel}</p> }
                        </div>
                        <div className="info-item">
                            <label>Industry:</label>
                            { this.state.editingJobInterests ?
                                <div className="text-input dropdown-input">
                                    <div className="dropdown-wrap">
                                        <input type="text" list="industries" className="type-dropdown" value={this.state.industry}
                                               onChange={(event)=>{this.setState({dropdownShowIndustry: !this.state.dropdownShowIndustry,industry: event.target.value})}}
                                               aria-describedby="basic-addon1"/>
                                        <datalist id="industries" className="datalist">
                                            {
                                                industryList && industryList.map((item, index) => {
                                                    return <option key={index}>{item}</option>
                                                })
                                            }
                                        </datalist>
                                    </div>
                                </div>
                                :
                                <p>{this.state.industry}</p> }
                        </div>
                        <div className="info-item">
                            <label>Business Size:</label>
                            { this.state.editingJobInterests ?
                                <div className="text-input dropdown-input">
                                    <div className="dropdown-wrap">
                                        <input type="dropdown" onClick={this.dropdownShowBSize} value={this.state.businessSize}/>
                                        <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowBSize} icon={faAngleDown} />
                                    </div>
                                    <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowBSize}>
                                        {
                                            bSizeList && bSizeList.map((type, index) => {
                                                return <button className="dropdown-item" key={index} onClick={(event)=>{this.setState({dropdownShowBSize: !this.state.dropdownShowBSize,businessSize: event.target.value})}} value={type}>{type}</button>
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                <p>{this.state.businessSize}</p> }
                        </div>
                    </div>
                    <div className="info-section smaller-section">
                        <div className="section-title">
                            <h6>Roles and skills</h6>
                            {
                                this.state.editingRoles ?
                                    <FontAwesomeIcon className="icon" onClick={this.updateProfileInfo} icon={faSave} />
                                    :
                                    <FontAwesomeIcon className="icon" onClick={() => {this.setState({editingRoles: true})}} icon={faPencilAlt} />
                            }
                        </div>
                        <div className="info-item">
                            <label>Roles of Interest:</label>
                            <div className="editing-layout">
                                { this.state.editingRoles &&
                                    <div className="text-input dropdown-input">
                                        <div className="dropdown-wrap">
                                            <input type="dropdown" onClick={this.dropdownShowRoles} value={"Choose role"}
                                                   readOnly={true}/>
                                            <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowRoles}
                                                             icon={faAngleDown}/>
                                        </div>
                                        <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowRoles}>
                                            {
                                                rolesList && rolesList.map((type, index) => {
                                                    return <button className="dropdown-item" key={index}
                                                                   onClick={this.dropdownChooseRoles}
                                                                   value={type}>{type}</button>
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="skills">
                                    {
                                        this.state.rolesInterest && this.state.rolesInterest.map((role, index) => {
                                            return <div className="skill" key={index} >{role}
                                                    <FontAwesomeIcon className="icon-close" onClick={(event) => this.removeFromList(event, index, true)} icon={faTimes} style={!this.state.editingRoles ? {display: "none"} : ''} />
                                                </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="info-item">
                            <label>Skills:</label>
                                <div className="editing-layout">
                                    { this.state.editingRoles &&
                                    <div className="text-input dropdown-input">
                                        <div className="dropdown-wrap">
                                            <input type="dropdown" onClick={this.dropdownShowSkills} value={"Choose skill"} readOnly={true}/>
                                            <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowSkills} icon={faAngleDown} />
                                        </div>
                                        <div className="dropdown-menu-custom skills-dropdown" hidden={!this.state.dropdownShowSkills}>
                                            {
                                                skillsList && skillsList.map((type, index) => {
                                                    return <button className="dropdown-item" key={index} onClick={this.dropdownChooseSkills} value={type}>{type}</button>
                                                })
                                            }
                                        </div>
                                    </div>
                                    }
                                    <div className="skills">
                                        {
                                            this.state.skills && this.state.skills.map((skill, index) => {
                                                return <div className="skill" key={index} >{skill}
                                                        <FontAwesomeIcon className="icon-close" onClick={(event) => this.removeFromList(event, index, false)} icon={faTimes} style={!this.state.editingRoles ? {display: "none"} : ''} />
                                                    </div>
                                            })
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }


}


const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(withRouter(MyAccount));
