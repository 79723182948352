import { call, put, takeLatest, all, select } from 'redux-saga/effects'
import Auth from '@aws-amplify/auth';
import { push } from 'react-router-redux';

const authR = (state) => state.authReducer;

function * setAuthInfo ({ email, pass }) {
    yield put({ type: 'AUTH_INFO', payload: { email, pass } })
}

function * authorizeSignIn ({ payload: { login, password, fromVerify }}) {
    try {
        yield put({ type: 'LOGGING', payload: { logging: true, error: null } });
        yield put({ type: 'SHOW_SPINNER' });

        const loginRes = yield Auth.signIn(login, password);

        const token = loginRes.signInUserSession.getAccessToken().jwtToken;
        const idToken = loginRes.signInUserSession.getIdToken().jwtToken;

        const currentUserInfo = yield Auth.currentUserInfo();

        const name = currentUserInfo.attributes['name'];
        const lastName = currentUserInfo.attributes['family_name'];
        const email = currentUserInfo.attributes['email'];
        const company = currentUserInfo.attributes['custom:company'];
        const username = currentUserInfo.attributes['sub'];
        const userType = currentUserInfo.attributes['custom:role'];

        // yield call(loadData, { token, idToken, username, errFetch: messageDoLogout })
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('idToken', idToken);

        yield put({ type: 'AUTH_SUCCESS', payload: { token, idToken } });
        yield put({ type: 'USER_INFO', payload: { username, name, lastName, email, company, userType } });
        yield put({ type: 'HIDE_SPINNER' });

        if(fromVerify) {
            yield put(push('/profile-info'));
        }
        yield put(push('/'));

    } catch (err) {
        yield put({ type: 'HIDE_SPINNER' });
        if (err.message.indexOf('User does not exist') !== -1) {
            return {
                error: { message: 'User does not exist' }
            }
        }
        if (err.message.indexOf('User is not confirmed') !== -1) {
            yield put(push('/confirm-email'));
        }
        else {
            yield put({ type: 'SIGN_IN_UNSUCCESSFUL', payload: { message: err.message } });
        }
    }
}

function * verifyEmail ({payload: { login, code }}) {
    try {
        yield put({ type: 'SHOW_SPINNER' });
        const loginRes = yield Auth.confirmSignUp(login, code);
        const authReducer = yield select(authR);
        const password = authReducer.pass;
        const formVerify = true;
        if(loginRes)
            yield call(authorizeSignIn, { payload: { login, password, formVerify } });
        const verified = true;

        yield put({ type: 'VERIFY_SUCCESS', payload: { verified } });
        yield put({ type: 'HIDE_SPINNER' })

    } catch (err) {
        if(err.message === "User cannot be confirmed. Current status is CONFIRMED") {
            const authReducer = yield select(authR);
            const password = authReducer.password;
            const formVerify = false;
            yield call(authorizeSignIn, { payload: { login, password, formVerify} });
            yield put(push('/'));
            const verified = true;
            yield put({ type: 'VERIFY_SUCCESS', payload: { verified } });
            yield put({ type: 'HIDE_SPINNER' })
        }
        else if (err.message.indexOf('Invalid code provided, please request a code again')!==-1) {
            yield put({ type: 'HIDE_SPINNER' });
            const verified = false;
            const errorMsg = err.message + " after the timer.";
            yield put({type: 'VERIFY_FAILED', payload: {verified, errorMsg}});
        }
        else {
            yield put({ type: 'HIDE_SPINNER' });
            const verified = false;
            yield put({type: 'VERIFY_FAILED', payload: {verified, errorMsg: 'Something went wrong, please wait to resend the code and try again.'}});
        }
    }
}

function * resendVerifyEmail ({payload : { login }}) {
    try {
        yield put({ type: 'SHOW_SPINNER' });

        yield Auth.resendSignUp(login);

        yield put({ type: 'HIDE_SPINNER' })

    } catch (err) {
        yield put({ type: 'HIDE_SPINNER' })
        if(err.message === "User cannot be confirmed. Current status is CONFIRMED") {
            const authReducer = yield select(authR);
            const password = authReducer.password;
            const formVerify = false;
            yield call(authorizeSignIn, { payload: { login, password, formVerify } });
            yield put(push('/profile-info'));
            const verified = true;
            yield put({ type: 'VERIFY_SUCCESS', payload: { verified } });
        }
        console.log(err.message);
    }
}



function * authorizeSignUp ({ payload: { login, password, name, lastName, role, company } }) {
    try {
        yield put({ type: 'LOGGING', payload: { logging: true, error: null } });
        yield put({ type: 'SHOW_SPINNER' });

        const email = login;
        let loginRes;
        if (role === 'recruiter') {
            loginRes = yield Auth.signUp({
                username: email, password, attributes: {
                    'name': name,
                    'family_name': lastName,
                    'custom:role': role,
                    'custom:company': company
                }
            });
        }
        else {
            loginRes = yield Auth.signUp({
                username: email, password, attributes: {
                    'name': name,
                    'family_name': lastName,
                    'custom:role': role
                }
            });
        }
        if (loginRes.error) {
            throw (loginRes.error);
        }

        window.localStorage.setItem('email', email);
        yield put({ type: 'SIGNUP_INFO', payload: { registered: true, email: email, password: password, userType: role } });
        yield put({ type: 'SET_TYPE', payload: { userType: role } });
        yield put({ type: 'HIDE_SPINNER' });

        yield put(push('/confirm-email'));
    } catch (error) {
        yield put({ type: 'HIDE_SPINNER' });
        console.log(error);
        let message;
        if (error.message.indexOf('username or password') !== -1 || error.message.indexOf('User does not exist') !== -1) {
            message = 'Invalid username or password.'
        } else if(error.message === '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6'){
            message = 'Password must contain lower case and upper case letters, number/s and special character/s'
        }   else if(error.message.indexOf('Password not long enough')!==-1){
            message = 'Password must contain at least 8 characters - letters, number/s and special character/s'
        }   else if(error.message.indexOf('1 validation error detected: Value at \'username\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+')!==-1){
            message = 'The email should be a valid email address'
        }   else if(error.message.indexOf('Username should be an email')!==-1){
            message = 'The email should be a valid email address'
        }
        else {
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 401:
                    message = 'Invalid credentials';
                    break;
                default:
                    message = error.message;
            }
        }
        yield put({ type: 'AUTH_FAILURE', payload: { message: message } });
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('idToken');
        window.localStorage.removeItem('username');
    }
}

function * cleanEverything () {
    yield put({ type: 'SHOW_SPINNER' });
    yield Auth.signOut({ global: true });
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('idToken');

    window.localStorage.removeItem('email');

    yield put({ type: 'LOGOUT_SUCCESS' });
    yield put({ type: 'CLEAR_DATA' });
    yield put({ type: 'HIDE_SPINNER' })
}
function * logout () {
    try {
        yield cleanEverything()
    } catch (err) {
        yield cleanEverything()
    }
}

// function * authForgot ({ payload: { email, sport } }) {
//     try {
//         const forgot = yield call(Utils.fetchData, 'POST', 'auth/forgot', null, {
//             email,
//             sport
//         });
//         if (forgot.response.code === -1) {
//             yield put({ type: 'SHOW_FORGOT_FAILURE' })
//         } else {
//             yield put({ type: 'SHOW_FORGOT_SUCCESS' })
//         }
//
//         // alert('Your password has been changed successfully!');
//     } catch (ex) {
//         //  yield call({ type: 'FORGOT_FAILURE' })
//     }
// }

function * userType ({ userType }) {
    yield put({ type: 'USER_TYPE', payload: { userType: userType } })
}

function * authSaga () {
    yield all([
        takeLatest('AUTH_REQUEST_SIGNIN', authorizeSignIn),
        takeLatest('AUTH_REQUEST_SIGNUP', authorizeSignUp),
        takeLatest('LOGOUT_REQUEST', logout),
        takeLatest('SET_AUTH_INFO', setAuthInfo),
        takeLatest('VERIFY_REQUEST', verifyEmail),
        takeLatest('RESEND_VERIFY_REQUEST', resendVerifyEmail),
        takeLatest('PUT_USER_TYPE', userType)
    ])
}

export default authSaga
