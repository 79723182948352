export const LOAD_DATA = 'LOAD_DATA';
export const STORE_ROUTES = 'STORE_ROUTES';
export const USER_INFO = 'USER_INFO';
export const PROFILE_INFO = 'PROFILE_INFO';
export const CLEAR_DATA = 'CLEAR_DATA';
export const LINKEDIN_TOKEN = 'LINKEDIN_TOKEN';
export const LINKEDIN_TOKEN_ERROR = 'LINKEDIN_TOKEN_ERROR';
export const LINKEDIN_PROFILE = 'LINKEDIN_PROFILE';
export const LINKEDIN_CLICKED = 'LINKEDIN_CLICKED';
export const LOAD_JOBS = 'LOAD_JOBS';
export const LOAD_DATA_UNSUCCESSFUL = 'LOAD_DATA_UNSUCCESSFUL';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SET_TYPE = 'SET_TYPE';


const initialState = {
    loaded: false,
    data: null,
    routes: [],
    username_: null,
    userName: null,
    userLastName: null,
    userEmail: null,
    companyRecruiter: null,
    userProfileInfo: null,
    enteredInfo: false,
    linkedInToken: null,
    linkedInTokenError: null,
    linkedInProfile: null,
    loadingLinkedInData: false,
    importDataClicked: false,
    jobs: null,
    loadedJobs: false,
    userType: null
};

export default function dataReducer (state = initialState, { type, payload }) {
    switch (type) {
        case LOAD_DATA: {
            return {...state, userProfileInfo: payload.data, loaded: true }
        }
        case STORE_ROUTES: {
            return { ...state, routes: payload.routes }
        }
        case USER_INFO: {
            return { ...state, username_: payload.username, userName: payload.name, userLastName: payload.lastName, userEmail: payload.email, companyRecruiter: payload.company, userType: payload.userType }
        }
        case PROFILE_INFO: {
            return { ...state, enteredInfo: payload.enteredInfo, userProfileInfo: payload.data, loaded: true }
        }
        case LINKEDIN_TOKEN: {
            return { ...state, linkedInToken: payload.accessToken, linkedInTokenError: null, importDataClicked: true }
        }
        case LINKEDIN_TOKEN_ERROR: {
            return { ...state, linkedInTokenError: payload.message }
        }
        case LINKEDIN_PROFILE: {
            return { ...state, linkedInProfile: payload.data }
        }
        case LINKEDIN_CLICKED: {
            return { ...state, loadingLinkedInData: true }
        }
        case LOAD_JOBS: {
            return { ...state, jobs: payload.data, loadedJobs: true }
        }
        case LOAD_DATA_UNSUCCESSFUL: {
            return { ...state, loaded: true }
        }
        case SHOW_SPINNER: {
            return { ...state, showSpinner: true }
        }
        case HIDE_SPINNER: {
            return { ...state, showSpinner: false }
        }
        case SET_TYPE: {
            return { ...state, userType: payload.userType }
        }
        case CLEAR_DATA: {
            return {
                loaded: false,
                data: null,
                username_: null,
                userName: null,
                userLastName: null,
                userEmail: null,
                companyRecruiter: null,
                userProfileInfo: null,
                enteredInfo: false,
                userType: null
            }
        }
        default:
            return state
    }
}
