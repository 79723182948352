import Profile from './dashboard'
import Home from './home'
import ProfileInfo from './profile-info'
import ConfirmCode from "./confirm-email";

export const createRoutes = (store) => ({
    path        : '/',
    customProp  : store,
    routes : [
        Home(store),
        Profile(store),
        ProfileInfo(store),
        ConfirmCode(store)
    ]
});

export default createRoutes
