import {all, put, takeLatest, select, call} from 'redux-saga/effects'
import axios from "axios";
import {push} from "react-router-redux";

// const authR = (state) => state.authReducer;
const dataR = (state) => state.dataReducer;

function* storeRoutes({ routes }) {
    yield put({ type: 'STORE_ROUTES', payload: { routes } })
}

function* getUserInfo({ username, name, lastName, email, company, userType }) {
    yield put({ type: 'SHOW_SPINNER' });
    yield put({ type: 'USER_INFO', payload: { username, name, lastName, email, company, userType } });
    try {
        let res = yield axios.get('https://9cdhrkmko0.execute-api.us-west-2.amazonaws.com/dev/users/' + username + "/" + email, { headers: { "Content-Type": "application/json" } });
        const data = res.data.Item;
        yield put({ type: 'LOAD_DATA', payload: { data } });
        if(!data) {
            yield put(push('/profile-info'));
        }
        yield put({ type: 'HIDE_SPINNER' });
    } catch (e) {
        yield put({ type: 'HIDE_SPINNER' });
        yield put({ type: 'LOAD_DATA_UNSUCCESSFUL' });
        console.log('error', e);
    }
}

function* getPostedJobs() {
    // yield put({ type: 'SHOW_SPINNER' });
    try {
        let res = yield axios.get('https://9cdhrkmko0.execute-api.us-west-2.amazonaws.com/dev/jobs', { headers: { "Content-Type": "application/json" } });
        const data = res.data;
        yield put({ type: 'LOAD_JOBS', payload: { data } });
        yield put({ type: 'HIDE_SPINNER' });
    } catch (e) {
        yield put({ type: 'HIDE_SPINNER' });
        console.log('error', e);
    }
}

function* getLinkedinProfile({ accessToken }) {
    yield put({ type: 'SHOW_SPINNER' });
    try {
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        let res = yield axios({
            method: 'get',
            url: proxyurl + "https://api.linkedin.com/v2/me?projection=(id,firstName,lastName,emailAddress,profilePicture(displayImage~:playableStreams))",
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'cache-control': 'no-cache',
                'X-Restli-Protocol-Version': '2.0.0'
            }
        });
        const data = res.data;
        yield put({ type: 'HIDE_SPINNER' });
        yield put({ type: 'LINKEDIN_PROFILE', payload: { data } });
    } catch (e) {
        yield put({ type: 'HIDE_SPINNER' });
        console.log('error', e);
    }
}

function* getLinkedinToken({code, importDataClicked}) {
    yield put({ type: 'SHOW_SPINNER' });
    yield put({ type: 'LINKEDIN_CLICKED', payload: { importDataClicked } });
    let url_callback;
    if(window.location.hostname === 'localhost')
        url_callback = "http://localhost:3000/profile-info";
    else {
        url_callback = "https://dev.konaty.com/profile-info";
    }
    try {
        // let res = yield axios.post('https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code='+ code + '&redirect_uri=http:%2F%2Flocalhost:3000%2Fprofile-info&client_id=77ul2m7qf6nful&client_secret=VVGzxsqe3sMhhhyE', { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        let res = yield axios({
            method: 'post',
            params: {
                grant_type: "authorization_code",
                code: code,
                redirect_uri: url_callback,
                client_id: "77ul2m7qf6nful",
                client_secret: "VVGzxsqe3sMhhhyE"
            },
            url: proxyurl + "https://www.linkedin.com/oauth/v2/accessToken",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        console.log("res:",res);
        const accessToken = res.data.access_token;
        yield put({ type: 'HIDE_SPINNER' });
        yield put({ type: 'LINKEDIN_TOKEN', payload: { accessToken, importDataClicked } });
        yield call(getLinkedinProfile, { accessToken: accessToken });
    } catch (e) {
        yield put({ type: 'HIDE_SPINNER' });
        console.log('error', e);
        const message = e.message;
        yield put({ type: 'LINKEDIN_TOKEN_ERROR', payload: { message } })
    }
}

function* setUserProfileInfo({ enteredInfo, data }) {
    yield put({ type: 'SHOW_SPINNER' });
    try {
        yield axios.put('https://9cdhrkmko0.execute-api.us-west-2.amazonaws.com/dev/users/create', data, { headers: { "Content-Type": "application/json" } });
        yield put({ type: 'HIDE_SPINNER' });
        yield put({ type: 'PROFILE_INFO', payload: { enteredInfo, data } })
    } catch (e) {
        yield put({ type: 'HIDE_SPINNER' });
        console.log('error', e);
    }
}

function* updateUserProfileInfo({ data }) {
    // yield put({ type: 'SHOW_SPINNER' });
    const dataReducer = yield select(dataR);
    const username = dataReducer.username_;
    try {
        yield axios.put('https://9cdhrkmko0.execute-api.us-west-2.amazonaws.com/dev/users/' + username, data, { headers: { "Content-Type": "application/json" } });
        // yield put({ type: 'HIDE_SPINNER' });
        yield put({ type: 'LOAD_INFO', payload: { data } })
    } catch (e) {
        // yield put({ type: 'HIDE_SPINNER' });
        console.log('error', e);
    }
}

function* utilsSaga() {
    yield all([
        takeLatest('STORE_ROUTES_SAGA', storeRoutes),
        takeLatest('SET_USER_INFO', getUserInfo),
        takeLatest('SET_PROFILE_INFO', setUserProfileInfo),
        takeLatest('UPDATE_USER_DATA', updateUserProfileInfo),
        takeLatest('LINKEDIN_ACCESS', getLinkedinToken),
        takeLatest('GET_JOBS', getPostedJobs),
    ])
}

export default utilsSaga
