import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { Provider } from 'react-redux'
import configureStore, { history } from './store/createStore'

const store = configureStore(window.__INITIAL_STATE__);

const MOUNT_NODE = document.getElementById('root');

let render = () => {
    const routes = require('./routes/index').default(store);
    store.dispatch({ type: 'STORE_ROUTES_SAGA', routes: routes.routes });
    ReactDOM.render(
        <Provider store={store}>
            <App history={history} routes={routes} store={store} />
        </Provider>,
        MOUNT_NODE
    );
};

render();
