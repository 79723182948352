import React from 'react'
import { connect } from 'react-redux'
import {Link, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import Footer from "../footer";
import MyAccountRecruiter from "../account-recruiter";
import PostedJobsView from "../posted-jobs";
import {S3Image} from "aws-amplify-react";
import Spinner from "../spinner";


class RecruiterProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: 'account',
            showMobMenu: false,
            dropdownSettings: false,
            image: null
        };
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            const {userProfileInfo} = this.props.dataReducer;
            if (userProfileInfo && userProfileInfo.imageUrl) {
                this.setState({
                    image: userProfileInfo.imageUrl
                })
            }
        }
    }

    async componentDidMount() {
        const {userProfileInfo} = this.props.dataReducer;
        if (userProfileInfo && userProfileInfo.imageUrl) {
            // const imageUrl = await Storage.vault.get(userProfileInfo.imageUrl);
            this.setState({
                image: userProfileInfo.imageUrl
            })
        }
    }

    openMenu = (activeMenu) => {
        this.setState({
            activeMenu: activeMenu
        });
        if(this.state.showMobMenu) {
            this.setState({
                showMobMenu: !this.state.showMobMenu
            })
        }
        if(activeMenu === 'logout') {
            this.props.dispatch({
                type: 'LOGOUT_REQUEST'
            });
            const { loggedOut } = this.props.authReducer;
            if (loggedOut) {
                this.props.history.push('/login');
            }
        }
    };

    toggleMobileMenu = () => {
        this.setState({
            showMobMenu: !this.state.showMobMenu
        })
    };

    showSettings = () => {
        this.setState({
            dropdownSettings: !this.state.dropdownSettings
        });
    };

    onSettings = () => {
        this.setState({
            dropdownSettings: !this.state.dropdownSettings
        })
    };

    onLogout = () => {
        this.setState({
            dropdownSettings: !this.state.dropdownSettings
        });
        this.props.dispatch({
            type: 'LOGOUT_REQUEST'
        });
        const { loggedOut } = this.props.authReducer;
        if (loggedOut) {
            this.props.history.push('/login');
        }
    };

    render() {
        let width = window.innerWidth;
        const { userName, userLastName, username_ } = this.props.dataReducer;
        window.onclick = (event) => {
            if (event.target.id !== "icon" && event.target.id !== "img" && event.target.id !== "img2") {
                this.setState({
                    dropdownSettings: false
                });
            }
        };
        const {showSpinner} = this.props.dataReducer;
        if(showSpinner) {
            return <Spinner />
        }
        return (
            <main>
                <div className="overlay" hidden={!this.state.showMobMenu}> </div>
                <header>
                    <div className="hamburger-menu">
                        <svg onClick={this.toggleMobileMenu} width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0V4H40V0H0ZM0 12V16H40V12H0ZM0 24V28H40V24H0Z" fill="#285A84"/>
                        </svg>
                    </div>
                    <div className="profile-header">
                        <div className="header">
                            <Link to={{ pathname: "/" }}>
                                <img src={require('../../assets/images/long-logo.svg')} alt="" className="human1"/>
                            </Link>
                        </div>
                        <div className="dropdown-menu-header">
                            <div className="dropdown-wrap">
                                <div className="img-wrap">
                                    { this.state.image ?
                                        <S3Image
                                            imgKey={"profile-pic-" + username_}
                                            alt="user avatar"
                                            id="img2" onClick={this.showSettings}
                                        /> :
                                        <img src={require('../../assets/images/no-profile-pic.jpg')} alt="" id="img" onClick={this.showSettings}/>
                                    }
                                    <FontAwesomeIcon className="icon" icon={faAngleDown} id="icon" onClick={this.showSettings}  />
                                </div>
                                <div className="dropdown-menu-custom" id="dropdown1" hidden={!this.state.dropdownSettings}>
                                    <button className="dropdown-item" onClick={this.onSettings}>
                                        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.2504 0L14.2528 5.04688C12.5756 5.66851 11.0355 6.53986 9.68601 7.62891L4.75358 5.95703L0 14.043L3.95072 17.418C3.79884 18.3344 3.72019 19.1837 3.72019 20C3.72019 20.8175 3.8012 21.6653 3.95072 22.582V22.5859L0 25.9609L4.75358 34.043L9.68204 32.375C11.0316 33.4646 12.5753 34.3311 14.2528 34.9531L15.2504 40H24.7496L25.7472 34.9531C27.4255 34.3311 28.9639 33.4611 30.314 32.3711L35.2464 34.043L39.996 25.9609L36.0493 22.582C36.2012 21.6656 36.2798 20.8163 36.2798 20C36.2798 19.1849 36.2007 18.3367 36.0493 17.4219V17.418L40 14.0391L35.2464 5.95703L30.318 7.625C28.9684 6.53536 27.4247 5.66887 25.7472 5.04688L24.7496 0H15.2504ZM18.6049 4H21.3951L22.186 8L24.3084 8.78906C25.5877 9.26294 26.7371 9.91351 27.7345 10.7188L29.4913 12.1328L33.4022 10.8125L34.7973 13.1836L31.6733 15.8555L32.031 18.0547V18.0586C32.1554 18.8085 32.2099 19.4375 32.2099 20C32.2099 20.5625 32.1554 21.1914 32.031 21.9414L31.6693 24.1406L34.7933 26.8125L33.3983 29.1875L29.4913 27.8633L27.7305 29.2812C26.7332 30.0865 25.5877 30.7371 24.3084 31.2109H24.3045L22.182 32L21.3911 36H18.6049L17.814 32L15.6916 31.2109C14.4123 30.7371 13.2629 30.0865 12.2655 29.2812L10.5087 27.8672L6.59777 29.1875L5.2027 26.8164L8.33068 24.1406L7.969 21.9492V21.9453C7.84635 21.1922 7.79014 20.5613 7.79014 20C7.79014 19.4375 7.84456 18.8086 7.969 18.0586L8.33068 15.8594L5.2027 13.1875L6.59777 10.8125L10.5087 12.1367L12.2655 10.7188C13.2629 9.91351 14.4123 9.26294 15.6916 8.78906L17.814 8L18.6049 4ZM20 12C15.5301 12 11.8601 15.607 11.8601 20C11.8601 24.393 15.5301 28 20 28C24.4699 28 28.1399 24.393 28.1399 20C28.1399 15.607 24.4699 12 20 12ZM20 16C22.2618 16 24.07 17.777 24.07 20C24.07 22.223 22.2618 24 20 24C17.7382 24 15.93 22.223 15.93 20C15.93 17.777 17.7382 16 20 16Z" fill="#191847"/>
                                        </svg>
                                        Settings
                                    </button>
                                    <button className="dropdown-item" onClick={this.onLogout}>
                                        <svg width="20" height="20" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                            <path className="color-change" d="M28.3333 11.6667L26 14L30.3333 18.3333H13.3333V21.6667H30.3333L26 26L28.3333 28.3333L36.6666 20L28.3333 11.6667ZM6.66665 31.6667H20V35H6.66665C4.83331 35 3.33331 33.5 3.33331 31.6667V8.33333C3.33331 6.5 4.83331 5 6.66665 5H20V8.33333H6.66665V31.6667Z" fill="black"/>
                                        </svg>
                                        Logout
                                    </button>
                                </div>
                            </div>
                            <button onClick={(event) => {event.preventDefault()}}>GO PREMIUM</button>
                        </div>
                    </div>
                </header>
                <div className="profile-wrap">
                    <section className={width > 500 ? "side-menu" : !this.state.showMobMenu ? "mobile-menu" : "mobile-menu toggleMenu"}>
                        <div className="profile-img">
                            { this.state.image ?
                                <S3Image
                                    imgKey={"profile-pic-" + username_}
                                    alt="user avatar"
                                /> :
                                <img src={require('../../assets/images/no-profile-pic.jpg')} alt=""/>
                            }
                            <p>{(userName || "") + " " +( userLastName || '')}</p>
                        </div>
                        <div className="menu-wrap">
                            <div className={"menu-item" + (this.state.activeMenu === 'account' ? ' active' : '')} onClick={() => this.openMenu('account')}>
                                <svg width="30" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color-change" d="M20 3.33334C10.8 3.33334 3.33331 10.8 3.33331 20C3.33331 29.2 10.8 36.6667 20 36.6667C29.2 36.6667 36.6666 29.2 36.6666 20C36.6666 10.8 29.2 3.33334 20 3.33334ZM11.7833 30.4667C12.5 28.9667 16.8666 27.5 20 27.5C23.1333 27.5 27.5166 28.9667 28.2166 30.4667C25.95 32.2667 23.1 33.3333 20 33.3333C16.9 33.3333 14.05 32.2667 11.7833 30.4667ZM30.6 28.05C28.2166 25.15 22.4333 24.1667 20 24.1667C17.5666 24.1667 11.7833 25.15 9.39998 28.05C7.69998 25.8167 6.66665 23.0333 6.66665 20C6.66665 12.65 12.65 6.66668 20 6.66668C27.35 6.66668 33.3333 12.65 33.3333 20C33.3333 23.0333 32.3 25.8167 30.6 28.05V28.05ZM20 10C16.7666 10 14.1666 12.6 14.1666 15.8333C14.1666 19.0667 16.7666 21.6667 20 21.6667C23.2333 21.6667 25.8333 19.0667 25.8333 15.8333C25.8333 12.6 23.2333 10 20 10ZM20 18.3333C18.6166 18.3333 17.5 17.2167 17.5 15.8333C17.5 14.45 18.6166 13.3333 20 13.3333C21.3833 13.3333 22.5 14.45 22.5 15.8333C22.5 17.2167 21.3833 18.3333 20 18.3333Z" fill="black"/>
                                </svg>
                                <label className={(this.state.activeMenu === 'account' ? ' active' : '')} onClick={() => this.openMenu('account')}>My Account</label>
                            </div>
                            <div className={"menu-item" + (this.state.activeMenu === 'messages' ? ' active' : '')} onClick={() => this.openMenu('messages')}>
                                <svg width="40" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color-change" d="M36.6663 10C36.6663 8.16669 35.1663 6.66669 33.333 6.66669H6.66634C4.83301 6.66669 3.33301 8.16669 3.33301 10V30C3.33301 31.8334 4.83301 33.3334 6.66634 33.3334H33.333C35.1663 33.3334 36.6663 31.8334 36.6663 30V10ZM33.333 10L19.9997 18.3334L6.66634 10H33.333ZM33.333 30H6.66634V13.3334L19.9997 21.6667L33.333 13.3334V30Z" fill="#191847"/>
                                </svg>
                                <label className={(this.state.activeMenu === 'messages' ? ' active' : '')} onClick={() => this.openMenu('messages')}>Messages</label>
                            </div>
                            <div className={"menu-item" + (this.state.activeMenu === 'postedJobs' ? ' active' : '')} onClick={() => this.openMenu('postedJobs')}>
                                <svg width="30" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color-change" d="M23.333 9.99998V6.66665H16.6663V9.99998H23.333ZM6.66634 13.3333V31.6666H33.333V13.3333H6.66634ZM33.333 9.99998C35.183 9.99998 36.6663 11.4833 36.6663 13.3333V31.6666C36.6663 33.5166 35.183 35 33.333 35H6.66634C4.81634 35 3.33301 33.5166 3.33301 31.6666L3.34967 13.3333C3.34967 11.4833 4.81634 9.99998 6.66634 9.99998H13.333V6.66665C13.333 4.81665 14.8163 3.33331 16.6663 3.33331H23.333C25.183 3.33331 26.6663 4.81665 26.6663 6.66665V9.99998H33.333Z" fill="#191847"/>
                                </svg>
                                <label className={(this.state.activeMenu === 'postedJobs' ? ' active' : '')} onClick={() => this.openMenu('postedJobs')}>Posted Jobs</label>
                            </div>
                            <div className={"menu-item" + (this.state.activeMenu === 'candidates' ? ' active' : '')} onClick={() => this.openMenu('candidates')}>
                                <svg width="30" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color-change" d="M35.683 19.3L20.683 4.29998C20.083 3.69998 19.2497 3.33331 18.333 3.33331H6.66634C4.83301 3.33331 3.33301 4.83331 3.33301 6.66665V18.3333C3.33301 19.25 3.69967 20.0833 4.31634 20.7L19.3163 35.7C19.9163 36.3 20.7497 36.6666 21.6663 36.6666C22.583 36.6666 23.4163 36.3 24.0163 35.6833L35.683 24.0166C36.2997 23.4166 36.6663 22.5833 36.6663 21.6666C36.6663 20.75 36.283 19.9 35.683 19.3ZM21.6663 33.35L6.66634 18.3333V6.66665H18.333V6.64998L33.333 21.65L21.6663 33.35Z" fill="#191847"/>
                                    <path className="color-change" d="M10.833 13.3333C12.2137 13.3333 13.333 12.214 13.333 10.8333C13.333 9.4526 12.2137 8.33331 10.833 8.33331C9.4523 8.33331 8.33301 9.4526 8.33301 10.8333C8.33301 12.214 9.4523 13.3333 10.833 13.3333Z" fill="#285A84"/>
                                </svg>
                                <label className={(this.state.activeMenu === 'candidates' ? ' active' : '')} onClick={() => this.openMenu('candidates')}>Candidates of Interest</label>
                            </div>
                            <div className={"menu-item" + (this.state.activeMenu === 'company' ? ' active' : '')} onClick={() => this.openMenu('company')}>
                                <svg width="30" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color-change" d="M19.9997 11.6667V5H3.33301V35H36.6663V11.6667H19.9997ZM9.99967 31.6667H6.66634V28.3333H9.99967V31.6667ZM9.99967 25H6.66634V21.6667H9.99967V25ZM9.99967 18.3333H6.66634V15H9.99967V18.3333ZM9.99967 11.6667H6.66634V8.33333H9.99967V11.6667ZM16.6663 31.6667H13.333V28.3333H16.6663V31.6667ZM16.6663 25H13.333V21.6667H16.6663V25ZM16.6663 18.3333H13.333V15H16.6663V18.3333ZM16.6663 11.6667H13.333V8.33333H16.6663V11.6667ZM33.333 31.6667H19.9997V28.3333H23.333V25H19.9997V21.6667H23.333V18.3333H19.9997V15H33.333V31.6667ZM29.9997 18.3333H26.6663V21.6667H29.9997V18.3333ZM29.9997 25H26.6663V28.3333H29.9997V25Z" fill="#191847"/>
                                </svg>
                                <label onClick={() => this.openMenu('company')} className={(this.state.activeMenu === 'company' ? ' active' : '')}>Company</label>
                            </div>
                            <div className={"menu-item" + (this.state.activeMenu === 'profiles' ? ' active' : '')} onClick={() => this.openMenu('profiles')}>
                                <svg width="30" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color-change" d="M30 26.8C28.7333 26.8 27.6 27.3 26.7333 28.0833L14.85 21.1666C14.9333 20.7833 15 20.4 15 20C15 19.6 14.9333 19.2166 14.85 18.8333L26.6 11.9833C27.5 12.8166 28.6833 13.3333 30 13.3333C32.7667 13.3333 35 11.1 35 8.33331C35 5.56665 32.7667 3.33331 30 3.33331C27.2333 3.33331 25 5.56665 25 8.33331C25 8.73331 25.0667 9.11665 25.15 9.49998L13.4 16.35C12.5 15.5166 11.3167 15 10 15C7.23333 15 5 17.2333 5 20C5 22.7666 7.23333 25 10 25C11.3167 25 12.5 24.4833 13.4 23.65L25.2667 30.5833C25.1833 30.9333 25.1333 31.3 25.1333 31.6666C25.1333 34.35 27.3167 36.5333 30 36.5333C32.6833 36.5333 34.8667 34.35 34.8667 31.6666C34.8667 28.9833 32.6833 26.8 30 26.8Z" fill="#191847"/>
                                </svg>
                                <label onClick={() => this.openMenu('profiles')} className={(this.state.activeMenu === 'profiles' ? ' active' : '')}>Shared Profiles</label>
                            </div>

                            <div className={"menu-item" + (this.state.activeMenu === 'settings' ? ' active' : '')} onClick={() => this.openMenu('settings')} hidden={width>500}>
                                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.2504 0L14.2528 5.04688C12.5756 5.66851 11.0355 6.53986 9.68601 7.62891L4.75358 5.95703L0 14.043L3.95072 17.418C3.79884 18.3344 3.72019 19.1837 3.72019 20C3.72019 20.8175 3.8012 21.6653 3.95072 22.582V22.5859L0 25.9609L4.75358 34.043L9.68204 32.375C11.0316 33.4646 12.5753 34.3311 14.2528 34.9531L15.2504 40H24.7496L25.7472 34.9531C27.4255 34.3311 28.9639 33.4611 30.314 32.3711L35.2464 34.043L39.996 25.9609L36.0493 22.582C36.2012 21.6656 36.2798 20.8163 36.2798 20C36.2798 19.1849 36.2007 18.3367 36.0493 17.4219V17.418L40 14.0391L35.2464 5.95703L30.318 7.625C28.9684 6.53536 27.4247 5.66887 25.7472 5.04688L24.7496 0H15.2504ZM18.6049 4H21.3951L22.186 8L24.3084 8.78906C25.5877 9.26294 26.7371 9.91351 27.7345 10.7188L29.4913 12.1328L33.4022 10.8125L34.7973 13.1836L31.6733 15.8555L32.031 18.0547V18.0586C32.1554 18.8085 32.2099 19.4375 32.2099 20C32.2099 20.5625 32.1554 21.1914 32.031 21.9414L31.6693 24.1406L34.7933 26.8125L33.3983 29.1875L29.4913 27.8633L27.7305 29.2812C26.7332 30.0865 25.5877 30.7371 24.3084 31.2109H24.3045L22.182 32L21.3911 36H18.6049L17.814 32L15.6916 31.2109C14.4123 30.7371 13.2629 30.0865 12.2655 29.2812L10.5087 27.8672L6.59777 29.1875L5.2027 26.8164L8.33068 24.1406L7.969 21.9492V21.9453C7.84635 21.1922 7.79014 20.5613 7.79014 20C7.79014 19.4375 7.84456 18.8086 7.969 18.0586L8.33068 15.8594L5.2027 13.1875L6.59777 10.8125L10.5087 12.1367L12.2655 10.7188C13.2629 9.91351 14.4123 9.26294 15.6916 8.78906L17.814 8L18.6049 4ZM20 12C15.5301 12 11.8601 15.607 11.8601 20C11.8601 24.393 15.5301 28 20 28C24.4699 28 28.1399 24.393 28.1399 20C28.1399 15.607 24.4699 12 20 12ZM20 16C22.2618 16 24.07 17.777 24.07 20C24.07 22.223 22.2618 24 20 24C17.7382 24 15.93 22.223 15.93 20C15.93 17.777 17.7382 16 20 16Z" fill="#191847"/>
                                </svg>

                                <label onClick={() => this.openMenu('settings')} className={(this.state.activeMenu === 'settings' ? ' active' : '')}>Settings</label>
                            </div>
                            <div className={"menu-item logout" + (this.state.activeMenu === 'logout' ? ' active' : '')} onClick={() => this.openMenu('logout')} hidden={width>500}>
                                <svg width="30" height="30" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color-change" d="M28.3333 11.6667L26 14L30.3333 18.3333H13.3333V21.6667H30.3333L26 26L28.3333 28.3333L36.6666 20L28.3333 11.6667ZM6.66665 31.6667H20V35H6.66665C4.83331 35 3.33331 33.5 3.33331 31.6667V8.33333C3.33331 6.5 4.83331 5 6.66665 5H20V8.33333H6.66665V31.6667Z" fill="black"/>
                                </svg>
                                <label onClick={() => this.openMenu('logout')} className={(this.state.activeMenu === 'logout' ? ' active' : '')}>Logout</label>
                            </div>
                        </div>
                    </section>
                    <section className="profile-content">
                        {   this.state.activeMenu === 'account' && <MyAccountRecruiter/>  }
                        {   this.state.activeMenu === 'postedJobs' && <PostedJobsView/>  }
                    </section>
                </div>
                <Footer />
            </main>
        )
    }


}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer
});

export default connect(mapStateToProps)(withRouter(RecruiterProfile))
