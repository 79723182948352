import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimesCircle,
    faPlusCircle,
    faAngleDown,
    faTimes,
    faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../footer";
import {Link, withRouter} from 'react-router-dom';
import {experienceTypeList, degreesList, experienceLevelList, jobTypeList, industryList, bSizeList, rolesList, skillsList} from '../../assets/libs';
import { Storage } from "aws-amplify";

class ProfileInfoApplicant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownShow: false,
            image: null,
            dropdownShowType: [],
            dropdownShowJob: false,
            dropdownShowLevel: false,
            dropdownShowIndustry: false,
            dropdownShowBSize: false,
            dropdownShowRoles: false,
            dropdownShowSkills: false,
            dropdownShowDegree: [],
            imgFile: null,

            phone_number: null,
            linkedIn: null,
            about: null,

            experienceTitle: [],
            experienceType: [],
            experienceCompany: [],
            experienceLocation: [],
            experienceCurrently: [],
            experienceStart: [],
            experienceEnd: [],
            experienceDescription: [],

            eduSchool: [],
            eduDegree: [],
            eduField: [],
            eduCurrently: [],
            eduStart: [],
            eduEnd: [],
            eduGPA: [],
            eduActivities: [],

            skill: '',
            role: '',
            bSize: undefined,
            industry: undefined,
            experienceLevel: undefined,
            jobType: undefined,
            chosenSkills: [],
            chosenRoles: [],

            savedEdu: [],
            savedExperience: [],
            educations: ['edu'],
            experiences: ['experience'],

            educationList: [],
            experienceList: [],

            showError: {},
            showPopup: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            const { linkedInProfile } = this.props.dataReducer;
            if(!!linkedInProfile) {
                this.toDataURL(linkedInProfile.profilePicture['displayImage~'].elements[0].identifiers[0].identifier, (dataUrl) => {
                    this.handleImgLinkedIn(dataUrl);
                });
                this.setState({
                    linkedIn: linkedInProfile.firstName.localized.en_US + " " + linkedInProfile.lastName.localized.en_US,
                    image: linkedInProfile.profilePicture['displayImage~'].elements[0].identifiers[0].identifier,
                })
            }
        }
    }

    handleImgLinkedIn = async (dataUrl) => {
        let blob = await fetch(dataUrl).then(res => res.blob());
        this.setState({
            imgFile: blob
        });
        console.log(blob);
    };

    componentDidMount() {
        const { linkedInProfile } = this.props.dataReducer;
        if(!!linkedInProfile) {
            this.toDataURL(linkedInProfile.profilePicture['displayImage~'].elements[0].identifiers[0].identifier, (dataUrl) => {
                this.handleImgLinkedIn(dataUrl);
            });
            this.setState({
                linkedIn: linkedInProfile.firstName.localized.en_US + " " + linkedInProfile.lastName.localized.en_US,
                image: linkedInProfile.profilePicture['displayImage~'].elements[0].identifiers[0].identifier
            })
        }
    }

    toDataURL(url, callback) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function() {
            let reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    setFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.handleImgLinkedIn(URL.createObjectURL(event.target.files[0]));
            this.setState({
                image: URL.createObjectURL(event.target.files[0])
            });
        }
        console.log(event.target.files[0]);

    };

    removeImg = () => {
        this.setState({
            image: null,
            imgFile: null
        });
    };

    dropdownShowExperienceType = (index) => {
        let dropdownShowType = this.state.dropdownShowType;
        dropdownShowType[index] = !this.state.dropdownShowType[index];
        this.setState({
            dropdownShowType: dropdownShowType
        })
    };

    dropdownShowSkills = () => {
        this.setState({
            dropdownShowSkills: !this.state.dropdownShowSkills
        })
    };

    dropdownShowRoles = () =>  {
        this.setState({
            dropdownShowRoles: !this.state.dropdownShowRoles
        })
    };

    dropdownShowBSize = () =>  {
        this.setState({
            dropdownShowBSize: !this.state.dropdownShowBSize
        })
    };

    dropdownShowIndustry = () =>  {
        this.setState({
            dropdownShowIndustry: !this.state.dropdownShowIndustry
        })
    };

    dropdownShowLevel = () =>  {
        this.setState({
            dropdownShowLevel: !this.state.dropdownShowLevel
        })
    };

    dropdownShowJobType = () =>  {
        this.setState({
            dropdownShowJob: !this.state.dropdownShowJob
        })
    };

    dropdownShowDegree = (index) =>  {
        let showError = this.state.showError;
        showError['eduDegree' + index] = false;
        let dropdownShowDegree = this.state.dropdownShowDegree;
        dropdownShowDegree[index] = !this.state.dropdownShowDegree[index];
        this.setState({
            dropdownShowDegree: dropdownShowDegree
        })
    };

    dropdownChoose = (event, index) => {
        event.preventDefault();
        let showError = this.state.showError;
        showError['experienceType'+index] = false;
        let experienceType = this.state.experienceType;
        experienceType[index] = event.target.value;
        let dropdownShowType = this.state.dropdownShowType;
        dropdownShowType[index] = !this.state.dropdownShowType[index];
        this.setState({
            dropdownShowType: dropdownShowType,
            experienceType: experienceType,
            showError: showError
        })
    };

    dropdownChooseSkills= (event) => {
        event.preventDefault();
        this.setState({
            dropdownShowSkills: !this.state.dropdownShowSkills,
            skill: event.target.value,
        });
        if(!this.state.chosenSkills.includes(event.target.value) && this.state.chosenSkills.length < 10) {
            this.setState({
                chosenSkills: this.state.chosenSkills.concat(event.target.value)
            });
        }
    };

    dropdownChooseRoles= (event) => {
        event.preventDefault();
        this.setState({
            dropdownShowRoles: !this.state.dropdownShowRoles,
            role: event.target.value
        });
        if(!this.state.chosenRoles.includes(event.target.value) && this.state.chosenRoles.length < 5) {
            this.setState({
                chosenRoles: this.state.chosenRoles.concat(event.target.value)
            });
        }
    };

    dropdownChooseBSize= (event) => {
        event.preventDefault();
        let showError = this.state.showError;
        showError['bSize'] = false;
        this.setState({
            dropdownShowBSize: !this.state.dropdownShowBSize,
            bSize: event.target.value,
            showError: showError
        })
    };

    dropdownChooseIndustry= (event) => {
        event.preventDefault();
        let showError = this.state.showError;
        showError['industry'] = false;
        this.setState({
            dropdownShowIndustry: !this.state.dropdownShowIndustry,
            industry: event.target.value,
            showError: showError
        })
    };

    dropdownChooseLevel= (event) => {
        event.preventDefault();
        let showError = this.state.showError;
        showError['experienceLevel'] = false;
        this.setState({
            dropdownShowLevel: !this.state.dropdownShowLevel,
            experienceLevel: event.target.value,
            showError: showError
        })
    };

    dropdownChooseJobType= (event) => {
        event.preventDefault();
        let showError = this.state.showError;
        showError['jobType'] = false;
        this.setState({
            dropdownShowJob: !this.state.dropdownShowJob,
            jobType: event.target.value,
            showError: showError
        })
    };

    dropdownChooseDegree= (event, index) => {
        event.preventDefault();
        let showError = this.state.showError;
        showError['eduDegree'+index] = false;

        let eduDegree = this.state.eduDegree;
        eduDegree[index] = event.target.value;

        let dropdownShowDegree = this.state.dropdownShowDegree;
        dropdownShowDegree[index] = !this.state.dropdownShowDegree[index];

        this.setState({
            dropdownShowDegree: dropdownShowDegree,
            eduDegree: eduDegree,
            showError: showError
        })
    };

    removeSkill = (index) => {
        const idx = index;
        this.setState({
            chosenSkills: this.state.chosenSkills.filter((skill, index) => index !== idx)
        });

    };

    removeRoll = (index) => {
        const idx = index;
        this.setState({
            chosenRoles: this.state.chosenRoles.filter((skill, index) => index !== idx)
        });
    };

    saveExperience = (event, index) => {
        event.preventDefault();
        const endDate = this.state.experienceCurrently[index] ? null : new Date(this.state.experienceEnd[index]);
        if(this.state.experienceTitle[index] && this.state.experienceTitle[index]!=='' &&
            this.state.experienceType[index] && this.state.experienceType[index]!=='' &&
            this.state.experienceCompany[index] && this.state.experienceCompany[index]!=='' &&
            this.state.experienceLocation[index] && this.state.experienceLocation[index]!=='' &&
            this.state.experienceStart[index] && this.state.experienceStart[index]!=='') {
                if ((!this.state.experienceCurrently[index] && this.state.experienceEnd[index] && this.state.experienceEnd[index]!=='') || this.state.experienceCurrently[index]) {
                    let saveExperience = this.state.savedExperience;
                    saveExperience[index] = true;
                    let experience = {
                        title: this.state.experienceTitle[index],
                        type: this.state.experienceType[index],
                        company: this.state.experienceCompany[index],
                        location: this.state.experienceLocation[index],
                        currentlyWorking: this.state.experienceCurrently[index],
                        startDate: new Date(this.state.experienceStart[index]),
                        endDate: endDate,
                        description: this.state.experienceDescription[index]
                    };
                    let experiences = this.state.experienceList;
                    if(!experiences[index])
                        experiences = experiences.concat(experience);
                    else {
                        experiences[index] = experience;
                    }
                    let showError = this.state.showError;
                    showError['experienceTitle' + index] = false;
                    showError['experienceType' + index] = false;
                    showError['experienceCompany' + index] = false;
                    showError['experienceLocation' + index] = false;
                    showError['experienceStart' + index] = false;
                    showError['experienceEnd' + index] = false;
                    this.setState({
                        savedExperience: saveExperience,
                        experienceList: experiences,
                        showError: showError
                    });
                }
                else {
                    let showError = this.state.showError;
                    showError['experienceEnd' + index] = true;
                    this.setState({
                        showError: showError
                    })
                }
        }
        else {
            let showError = this.state.showError;
            if(!this.state.experienceTitle[index] || this.state.experienceTitle[index]==='')
                showError['experienceTitle' + index] = true;
            if(!this.state.experienceType[index] || this.state.experienceType[index]==='')
                showError['experienceType' + index] = true;
            if(!this.state.experienceCompany[index] || this.state.experienceCompany[index]==='')
                showError['experienceCompany' + index] = true;
            if(!this.state.experienceLocation[index] || this.state.experienceLocation[index]==='')
                showError['experienceLocation' + index] = true;
            if(!this.state.experienceStart[index] || this.state.experienceStart[index]==='')
                showError['experienceStart' + index] = true;
            if(!this.state.experienceCurrently[index] && (!this.state.experienceEnd[index] || this.state.experienceEnd[index]===''))
                showError['experienceEnd' + index] = true;
            else {
                showError['experienceEnd' + index] = false;
            }
            this.setState({
                showError: showError
            })
        }
    };

    saveEdu = (event, index) => {
        event.preventDefault();
        const endDate = this.state.eduCurrently[index] ? null : new Date(this.state.eduEnd[index]);
        if(this.state.eduSchool[index] && this.state.eduSchool[index]!=='' &&
            this.state.eduDegree[index] && this.state.eduDegree[index]!=='' &&
            this.state.eduField[index] && this.state.eduField[index]!=='' &&
            this.state.eduStart[index] && this.state.eduStart[index]!=='') {
            if ((!this.state.eduCurrently[index] && this.state.eduEnd[index] && this.state.eduEnd[index] !== '') || this.state.eduCurrently[index]) {
                let saveEducation = this.state.savedEdu;
                saveEducation[index] = true;
                let education = {
                    school: this.state.eduSchool[index],
                    degree: this.state.eduDegree[index],
                    field: this.state.eduField[index],
                    currentlyStudying: this.state.eduCurrently[index],
                    startDate: new Date(this.state.eduStart[index]),
                    endDate: endDate,
                    gpa: this.state.eduGPA[index],
                    activities: this.state.eduActivities[index]
                };
                let educations = this.state.educationList;
                if(!educations[index])
                    educations = educations.concat(education);
                else {
                    educations[index] = education;
                }
                let showError = this.state.showError;
                showError['eduSchool' + index] = false;
                showError['eduDegree' + index] = false;
                showError['eduField' + index] = false;
                showError['eduStart' + index] = false;
                showError['eduEnd' + index] = false;
                this.setState({
                    savedEdu: saveEducation,
                    educationList: educations,
                    showError: showError
                });
            }
            else {
                let showError = this.state.showError;
                showError['eduEnd' + index] = true;
                this.setState({
                    showError: showError
                })
            }
        }
        else {
            let showError = this.state.showError;
            if(!this.state.eduSchool[index] && this.state.eduSchool[index]==='')
                showError['eduSchool' + index] = true;
            if(!this.state.eduDegree[index] && this.state.eduDegree[index]==='')
                showError['eduDegree' + index] = true;
            if(!this.state.eduField[index] && this.state.eduField[index]==='')
                showError['eduField' + index] = true;
            if(!this.state.eduStart[index] && this.state.eduStart[index]==='')
                showError['eduStart' + index] = true;
            if(!this.state.eduCurrently[index] && (!this.state.eduEnd[index] || this.state.eduEnd[index]===''))
                showError['eduEnd' + index] = true;
            else {
                showError['eduEnd' + index] = false;
            }
            this.setState({
                showError: showError
            })
        }
    };

    deleteExperience = (event, index) => {
        event.preventDefault();
        const {savedExperience} = this.state;
        savedExperience.splice(index, 1);
        let showError = this.state.showError;
        showError['experienceTitle' + index] = false;
        showError['experienceType' + index] = false;
        showError['experienceCompany' + index] = false;
        showError['experienceLocation' + index] = false;
        showError['experienceStart' + index] = false;
        showError['experienceEnd' + index] = false;
        const {experienceTitle} = this.state;
        const {experienceCurrently} = this.state;
        const {experienceType} = this.state;
        const {experienceCompany} = this.state;
        const {experienceLocation} = this.state;
        const {experienceStart} = this.state;
        const {experienceEnd} = this.state;
        const {experienceDescription} = this.state;
        const {experiences} = this.state;
        const {experienceList} = this.state;
        if(experiences.length > 1) {
            experiences.splice(index, 1);
            experienceTitle.splice(index,1);
            experienceCurrently.splice(index,1);
            experienceType.splice(index,1);
            experienceCompany.splice(index,1);
            experienceLocation.splice(index,1);
            experienceStart.splice(index,1);
            experienceEnd.splice(index,1);
            experienceDescription.splice(index,1);
            experienceList.splice(index,1);
        }
        this.setState({
            experienceCurrently: experienceCurrently,
            experienceList: experienceList,
            experienceTitle: experienceTitle,
            experienceType:experienceType ,
            experienceCompany: experienceCompany,
            experienceLocation: experienceLocation,
            experienceStart: experienceStart,
            experienceEnd: experienceEnd,
            experienceDescription: experienceDescription,
            experiences: experiences,
            showError: showError,
            savedExperience: savedExperience
        });
    };

    deleteEdu = (event, index) => {
        event.preventDefault();

        const {savedEdu} = this.state;
        savedEdu.splice(index, 1);

        let showError = this.state.showError;
        showError['eduSchool' + index] = false;
        showError['eduDegree' + index] = false;
        showError['eduField' + index] = false;
        showError['eduStart' + index] = false;
        showError['eduEnd' + index] = false;

        const {eduCurrently} = this.state;
        const {eduActivities} = this.state;
        const {eduSchool} = this.state;
        const {eduDegree} = this.state;
        const {eduField} = this.state;
        const {eduStart} = this.state;
        const {eduEnd} = this.state;
        const {eduGPA} = this.state;
        const {educations, educationList} = this.state;
        if(educations.length > 1) {
            eduCurrently.splice(index,1);
            eduActivities.splice(index,1);
            eduSchool.splice(index,1);
            eduDegree.splice(index,1);
            eduField.splice(index,1);
            eduStart.splice(index,1);
            eduEnd.splice(index,1);
            eduGPA.splice(index,1);
            educations.splice(index,1);
            educationList.splice(index,1);
        }
        this.setState({
            savedEdu: savedEdu,
            educationList: educationList,
            eduCurrently: eduCurrently,
            educations: educations,
            eduSchool: eduSchool,
            eduDegree: eduDegree,
            eduField: eduField,
            eduStart: eduStart,
            eduEnd: eduEnd,
            eduGPA: eduGPA,
            eduActivities: eduActivities,
            showError: showError,
        })
    };

    addEdu = () => {
        this.setState({
            educations: this.state.educations.concat("edu")
        });
        let index = this.state.educations.length-1;
        let element = document.getElementById("edu"+index);
        element.scrollIntoView({ behavior: 'smooth'});
    };

    addExperience = () => {
        this.setState({
            experiences: this.state.experiences.concat("experience")
        });
        let index = this.state.experiences.length-1;
        let element = document.getElementById("experience"+index);
        element.scrollIntoView({ behavior: 'smooth'});
    };

    createProfile = async (event) => {
        event.preventDefault();
        const {userName, userLastName, userEmail, username_} = this.props.dataReducer;
        if (this.state.phone_number === '' || !this.state.phone_number) {
            let showError = this.state.showError;
            showError['phone_number'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.about === '' || !this.state.about) {
            let showError = this.state.showError;
            showError['about'] = true;
            this.setState({
                showError: showError
            })
        }

        if (this.state.experienceList.length < 1) {
            let showError = this.state.showError;
            showError['experienceTitle' + 0] = true;
            showError['experienceType' + 0] = true;
            showError['experienceCompany' + 0] = true;
            showError['experienceLocation' + 0] = true;
            showError['experienceStart' + 0] = true;
            showError['experienceEnd' + 0] = true;
            this.setState({
                showError: showError
            })
        }

        if (this.state.educationList.length < 1) {
            let showError = this.state.showError;
            showError['eduSchool'+0] = true;
            showError['eduDegree'+0] = true;
            showError['eduField'+0] = true;
            showError['eduStart'+0] = true;
            showError['eduEnd'+0] = true;
            this.setState({
                showError: showError
            })
        }

        if (this.state.jobType === '' || !this.state.jobType) {
            let showError = this.state.showError;
            showError['jobType'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.experienceLevel === '' || !this.state.experienceLevel) {
            let showError = this.state.showError;
            showError['experienceLevel'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.industry === '' || !this.state.industry) {
            let showError = this.state.showError;
            showError['industry'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.bSize === '' || !this.state.bSize) {
            let showError = this.state.showError;
            showError['bSize'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.chosenRoles.length < 1) {
            let showError = this.state.showError;
            showError['roles'] = true;
            this.setState({
                showError: showError
            })
        }
        if (this.state.chosenSkills.length < 1) {
            let showError = this.state.showError;
            showError['skills'] = true;
            this.setState({
                showError: showError
            })
        }
        let flag = false;
        for (let key in this.state.showError) {
            if (this.state.showError.hasOwnProperty(key) && this.state.showError[key] === true)
                flag = true;
        }
        if (!flag) {
            const attachmentImg = this.state.imgFile ? await this.s3Upload(this.state.imgFile, username_) : null;

            let data = {
                username: username_,
                name: userName,
                lastName: userLastName,
                email: userEmail,
                applicant: true,
                phone_number: '+1 ' + this.state.phone_number,
                linkedIn: this.state.linkedIn,
                about: this.state.about,
                experiences: this.state.experienceList,
                educations: this.state.educationList,
                jobTypeInterest: this.state.jobType,
                experienceLevel: this.state.experienceLevel,
                industry: this.state.industry,
                businessSize: this.state.bSize,
                rolesInterest: this.state.chosenRoles,
                skills: this.state.chosenSkills,
                imageUrl: attachmentImg
            };
            let enteredInfo = true;
            this.props.dispatch({
                type: 'SET_PROFILE_INFO',
                enteredInfo,
                data
            });
            if (this.props.location.pathname === '/profile-info')
                this.props.history.push("/");
        }
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };

    async s3Upload(file, username) {
        const filename = "profile-pic-"+username;
        const stored = await Storage.vault.put(filename, file, {
            contentType: file.type,
        });
        console.log("--------stored img result", stored);
        return stored.key;
    }

    editingExperience = (index) => {
        let saveExperience = this.state.savedExperience;
        saveExperience[index] = false;
        this.setState({
            saveExperience: saveExperience,
        })
    };

    editingEdu = (index) => {
        let saveEdu = this.state.savedEdu;
        saveEdu[index] = false;
        this.setState({
            saveEdu: saveEdu
        })
    };

    onChangeExperience = (event, index, value) => {
        let showError = this.state.showError;
        showError[value+index] = false;
        this.setState({showError: showError});
        if(value === 'experienceTitle') {
            let experienceTitle = this.state.experienceTitle;
            experienceTitle[index] = event.target.value;
            this.setState({experienceTitle: experienceTitle})
        }
        if(value === 'experienceCompany') {
            let experienceCompany = this.state.experienceCompany;
            experienceCompany[index] = event.target.value;
            this.setState({experienceCompany: experienceCompany})
        }
        if(value === 'experienceLocation') {
            let experienceLocation = this.state.experienceLocation;
            experienceLocation[index] = event.target.value;
            this.setState({experienceLocation: experienceLocation})
        }
        if(value === 'experienceCurrently') {
            let experienceCurrently = this.state.experienceCurrently;
            experienceCurrently[index] = !this.state.experienceCurrently[index];
            this.setState({experienceCurrently: experienceCurrently})
        }
        if(value === 'experienceStart') {
            let experienceStart = this.state.experienceStart;
            experienceStart[index] = event.target.value;
            this.setState({experienceStart: experienceStart})
        }
        if(value === 'experienceEnd') {
            let experienceEnd = this.state.experienceEnd;
            experienceEnd[index] = event.target.value;
            this.setState({experienceEnd: experienceEnd})
        }
        if(value === 'experienceDescription') {
            let experienceDescription = this.state.experienceDescription;
            experienceDescription[index] = event.target.value;
            this.setState({experienceDescription: experienceDescription})
        }
    };

    onChangeEdu = (event, index, value) => {
        let showError = this.state.showError;
        showError[value+index] = false;
        this.setState({showError: showError});
        if(value === 'eduSchool') {
            let eduSchool = this.state.eduSchool;
            eduSchool[index] = event.target.value;
            this.setState({eduSchool: eduSchool})
        }
        if(value === 'eduField') {
            let eduField = this.state.eduField;
            eduField[index] = event.target.value;
            this.setState({eduField: eduField})
        }
        if(value === 'eduCurrently') {
            let eduCurrently = this.state.eduCurrently;
            eduCurrently[index] = !this.state.eduCurrently[index];
            this.setState({eduCurrently: eduCurrently})
        }
        if(value === 'eduStart') {
            let eduStart = this.state.eduStart;
            eduStart[index] = event.target.value;
            this.setState({eduStart: eduStart})
        }
        if(value === 'eduEnd') {
            let eduEnd = this.state.eduEnd;
            eduEnd[index] = event.target.value;
            this.setState({eduEnd: eduEnd})
        }
        if(value === 'eduGPA') {
            let eduGPA = this.state.eduGPA;
            eduGPA[index] = event.target.value;
            this.setState({eduGPA: eduGPA})
        }
        if(value === 'eduActivities') {
            let eduActivities = this.state.eduActivities;
            eduActivities[index] = event.target.value;
            this.setState({eduActivities: eduActivities})
        }
    };

    render() {
        const { userName, userLastName, userEmail, linkedInTokenError, linkedInProfile, loadingLinkedInData, importDataClicked } = this.props.dataReducer;
        let url_callback;
        if(window.location.hostname === 'localhost')
            url_callback = "http%3A%2F%2Flocalhost:3000%2Fprofile-info";
        else {
            url_callback = "https%3A%2F%2Fdev.konaty.com%2Fprofile-info";
        }


        if(!linkedInProfile && !loadingLinkedInData && importDataClicked) {
            return <div className='spinner-wrapper'>
                <span className='spinner' />
            </div>
        }

        return (
            <main>
                { this.state.showPopup ?
                    <div className='expenses-overlay'>
                        <div className='expenses-details warning-details'>
                            <h1>Confirm</h1>
                            <h2>When the data is imported from you linkedIn profile some of the input fields will be auto-populated with that data. <br/> Do you want to proceed?</h2>
                            <div className="buttons-wrapper">
                                <button className='btn-custom-cancel' onClick={(event) => {event.preventDefault(); this.setState({showPopup: false})}}>No</button>
                                <button className='btn-custom'><a href={"https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77ul2m7qf6nful&redirect_uri="+ url_callback +"&scope=r_liteprofile%20r_emailaddress"}>Yes</a></button>
                            </div>
                        </div>
                    </div> : null
                }
                <header className="header-info">
                    <div>
                        <Link to={"/"}><img src={require('../../assets/images/long-logo.svg')} alt="" className="human1"/></Link>
                        <h1>Create your profile and land your dream job</h1>
                    </div>
                </header>
                <section className="section-form-info">
                    <form>
                        <label className="error-msg" hidden={!linkedInTokenError}>An error occurred, please try again connecting with LinkedIn by clicking the button bellow</label>
                        <div className="import-cv">
                            <div>
                                <label>{linkedInProfile ? "Your LinkedIn profile data have been imported" : "Import your profile data from LinkedIn"}</label>
                            </div>
                            <div className="linkedin-btn">
                                {/*<button className="btn-custom" onClick={(event) => {event.preventDefault(); this.setState({showPopup: true})}}>*/}
                                <div className="btn-custom">
                                    <a href={"https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77ul2m7qf6nful&redirect_uri="+ url_callback +"&scope=r_liteprofile%20r_emailaddress"}>
                                        IMPORT DATA FROM LINKEDIN
                                    </a>
                                </div>
                            </div>
                        </div>
                        <span>*Required fields</span>
                        <h4>Personal Information</h4>
                        <div className="input-wrap">
                            <div className="text-input first-child disabled">
                                <label>Name</label>
                                <input type="text" defaultValue={userName}/>
                            </div>
                            <div className="text-input disabled">
                                <label>Last Name</label>
                                <input type="text" defaultValue={userLastName}/>
                            </div>
                        </div>
                        <div className="text-input disabled">
                            <label>Email</label>
                            <input type="text" defaultValue={userEmail}/>
                        </div>
                        <div className="text-input">
                            <label>Phone*</label>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <button className="btn btn-outline-secondary custom-class" type="button" onClick={() => {this.setState({dropdownShow: !this.state.dropdownShow})}}
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src={"https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png"} className="flag_img"
                                        alt="United States" itemProp="image" /> <span>+1</span>
                                    </button>
                                </div>
                                <input type="number" onChange={(event) => {let showError = this.state.showError;
                                                                                showError['phone_number'] = false;
                                                                                        this.setState({phone_number: event.target.value, showError: showError})}} />
                            </div>
                            <label hidden={!this.state.showError['phone_number']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input">
                            <label>LinkedIn</label>
                            <input type="text" value={this.state.linkedIn || ""} onChange={(event) => {this.setState({linkedIn: event.target.value})}} />
                        </div>
                        <h4>Summary</h4>
                        <div className="text-input">
                            <label>About*</label>
                            <textarea onChange={(event) => {
                                let showError = this.state.showError;
                                showError['about'] = false;
                                this.setState({about: event.target.value, showError: showError})}} />
                            <label hidden={!this.state.showError['about']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="photo-input">
                            <label>Photo</label>
                            <div className="upload-wrap">
                                <span hidden={this.state.image!==null}><label htmlFor="file-input" className="file-upload">Upload an image</label></span>
                                <input type="file" id="file-input" hidden={true} onChange={this.setFile} />
                                <div className="img-wrap">
                                    <img id="target" className="file-img" src={this.state.image} hidden={this.state.image===null} alt=""/>
                                    <FontAwesomeIcon className="icon-close" onClick={this.removeImg} icon={faTimesCircle} hidden={this.state.image===null} />
                                </div>
                            </div>
                        </div>
                        <div className="add-section-wrap">
                            <h4>Experience</h4>
                            <div onClick={this.addExperience}>
                                <FontAwesomeIcon className="icon-plus" icon={faPlusCircle} />
                                <label>Add new experience</label>
                            </div>
                        </div>
                        {
                            this.state.experiences.map((experience, index) => {
                                return <div key={index} className="section-wrap">
                                    <div className="wrapper">
                                        <h6 style={{marginTop: 1 + 'rem'}}>Experience {index + 1}</h6>
                                            {
                                                this.state.savedExperience[index] &&
                                                    <FontAwesomeIcon className="icon cursor-pointer" onClick={()=>this.editingExperience(index)} title="Edit" icon={faPencilAlt} />
                                            }
                                    </div>
                                    <div className="text-input">
                                        <label>Title*</label>
                                        <input type="text" className={this.state.savedExperience[index] ? "disabled" : ''} disabled={this.state.savedExperience[index]}
                                               value={this.state.experienceTitle[index] || ''}
                                               onChange={(event) => this.onChangeExperience(event, index, 'experienceTitle')} />
                                        <label  hidden={!this.state.showError['experienceTitle' + index]} className='error-required'>This filed is required!</label>
                                    </div>
                                    <div className="text-input dropdown-input">
                                        <label>Experience Type*</label>
                                        <div className="dropdown-wrap">
                                            <input type="dropdown" onClick={()=>this.dropdownShowExperienceType(index)} value={this.state.experienceType[index] || ''} readOnly={true} className={this.state.savedExperience[index] ? "disabled" : ''} disabled={this.state.savedExperience[index]}/>
                                            <FontAwesomeIcon className="icon-arrow-down" icon={faAngleDown} onClick={()=>this.dropdownShowExperienceType(index)} style={this.state.savedExperience[index] ? {display: 'none'} : {}} />
                                        </div>
                                        <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowType[index]}>
                                            {
                                                experienceTypeList.map((type, idx) => {
                                                    return <button className="dropdown-item" key={idx} onClick={(event)=>this.dropdownChoose(event, index)} value={type}>{type}</button>
                                                })
                                            }
                                        </div>
                                        <label hidden={!this.state.showError['experienceType' + index]} className='error-required'>This filed is required!</label>
                                    </div>
                                    <div className="text-input">
                                        <label>Company*</label>
                                        <input className={this.state.savedExperience[index] ? "disabled" : ''} disabled={this.state.savedExperience[index]}
                                               value={this.state.experienceCompany[index] || ''}
                                               onChange={(event) => this.onChangeExperience(event, index, 'experienceCompany')}  />
                                        <label hidden={!this.state.showError['experienceCompany' + index]} className='error-required'>This filed is required!</label>
                                    </div>
                                    <div className="text-input">
                                        <label>Location*</label>
                                        <input className={this.state.savedExperience[index] ? "disabled" : ''} disabled={this.state.savedExperience[index]}
                                               value={this.state.experienceLocation[index] || ''}
                                               onChange={(event) => this.onChangeExperience(event, index, 'experienceLocation')}  />
                                        <label hidden={!this.state.showError['experienceLocation' + index]} className='error-required'>This filed is required!</label>
                                    </div>
                                    <div className="chb-input">
                                        <input type="checkbox" className={this.state.savedExperience[index] ? "disabled" : ''} disabled={this.state.savedExperience[index]}
                                               value={this.state.experienceCurrently[index] || ''}
                                               onChange={(event) => this.onChangeExperience(event, index, 'experienceCurrently')}  />
                                        <label>I currently work in this role</label>
                                    </div>
                                    <div className="input-wrap">
                                        <div className="text-input first-child">
                                            <label>Start Date*</label>
                                            <input type="date" className={this.state.savedExperience[index] ? "disabled" : ''} disabled={this.state.savedExperience[index]}
                                                   value={this.state.experienceStart[index] || ''} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.onChangeExperience(event, index, 'experienceStart')}  />
                                            <label hidden={!this.state.showError['experienceStart' + index]} className='error-required'>This filed is required!</label>
                                        </div>
                                        <div className="text-input">
                                            <label>End Date*</label>
                                            <input type="date" className={this.state.savedExperience[index] ? "disabled" : ''}
                                                   disabled={this.state.savedExperience[index] || this.state.experienceCurrently[index]}
                                                   value={this.state.experienceEnd[index] || ''} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.onChangeExperience(event, index, 'experienceEnd')}  />
                                            <label hidden={!this.state.showError['experienceEnd' + index]} className='error-required'>This filed is required!</label>
                                        </div>
                                    </div>
                                    <div className="text-input">
                                        <label>Description</label>
                                        <textarea disabled={this.state.savedExperience[index]} className={this.state.savedExperience[index] ? "disabled" : ''}
                                                  value={this.state.experienceDescription[index] || ''}
                                                  onChange={(event) => this.onChangeExperience(event, index, 'experienceDescription')}  />
                                    </div>
                                    <div className="btn-wrap" id={'experience'+index}>
                                        <button className="btn-custom-del" onClick={(event) => this.deleteExperience(event, index)}>Delete experience</button>
                                        <button className="btn-custom" onClick={(event) => this.saveExperience(event, index)}>Save experience</button>
                                    </div>
                                </div>
                            })
                        }
                        <div className="add-section" onClick={this.addExperience}>
                            <FontAwesomeIcon className="icon-plus" icon={faPlusCircle} />
                            <label>Add new experience</label>
                        </div>
                        <div className="add-section-wrap">
                            <h4>Education</h4>
                            <div onClick={this.addEdu}>
                                <FontAwesomeIcon className="icon-plus" icon={faPlusCircle} />
                                <label>Add new education</label>
                            </div>
                        </div>
                        {
                            this.state.educations.map((edu, index) => {
                                return <div key={index} className="section-wrap">
                                    <div className="wrapper">
                                        <h6 style={{marginTop: 1.5 + 'rem'}}>Education {index + 1}</h6>
                                        {
                                            this.state.savedEdu[index] &&
                                            <FontAwesomeIcon className="icon cursor-pointer" onClick={()=>this.editingEdu(index)} title="Edit" icon={faPencilAlt} />
                                        }
                                    </div>
                                    <div className="text-input">
                                        <label>School*</label>
                                        <input type="text" disabled={this.state.savedEdu[index]} className={this.state.savedEdu[index] ? "disabled" : ''}
                                               value={this.state.eduSchool[index] || ''}
                                               onChange={(event) => this.onChangeEdu(event, index, 'eduSchool')} />
                                        <label hidden={!this.state.showError['eduSchool' + index]} className='error-required'>This filed is required!</label>
                                    </div>
                                    <div className="text-input dropdown-input">
                                        <label>Degree*</label>
                                        <div className="dropdown-wrap">
                                            <input type="dropdown" onClick={()=>this.dropdownShowDegree(index)} value={this.state.eduDegree[index] || ''} readOnly={true} disabled={this.state.savedEdu[index]} className={this.state.savedEdu[index] ? "disabled" : ''}/>
                                            <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowDegree} icon={faAngleDown} style={this.state.savedEdu[index] ? {display: 'none'} : {}}/>
                                        </div>
                                        <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowDegree[index]}>
                                            {
                                                degreesList.map((type, idx) => {
                                                    return <button className="dropdown-item" key={idx} onClick={(event) => this.dropdownChooseDegree(event,index)} value={type}>{type}</button>
                                                })
                                            }
                                        </div>
                                        <label hidden={!this.state.showError['eduDegree' + index]} className='error-required'>This filed is required!</label>
                                    </div>
                                    <div className="text-input">
                                        <label>Field of Study*</label>
                                        <input className={this.state.savedEdu[index] ? "disabled" : ''} type="text" disabled={this.state.savedEdu[index]}
                                               value={this.state.eduField[index] || ''}
                                               onChange={(event) => this.onChangeEdu(event, index, 'eduField')}/>
                                        <label hidden={!this.state.showError['eduField' + index]} className='error-required'>This filed is required!</label>
                                    </div>
                                    <div className="chb-input">
                                        <input className={this.state.savedEdu[index] ? "disabled" : ''} type="checkbox" disabled={this.state.savedEdu[index]}
                                               value={this.state.eduCurrently[index] || ''}
                                               onChange={(event) => this.onChangeEdu(event, index, 'eduCurrently')}/>
                                        <label>I currently study in this school</label>
                                    </div>
                                    <div className="input-wrap">
                                        <div className="text-input first-child">
                                            <label>Start Date*</label>
                                            <input className={this.state.savedEdu[index] ? "disabled" : ''} type="date" disabled={this.state.savedEdu[index]}
                                                   value={this.state.eduStart[index] || ''} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.onChangeEdu(event, index, 'eduStart')}/>
                                            <label hidden={!this.state.showError['eduStart' + index]} className='error-required'>This filed is required!</label>
                                        </div>
                                        <div className="text-input">
                                            <label>End Date*</label>
                                            <input className={this.state.savedEdu[index] ? "disabled" : ''} type="date"
                                                   disabled={this.state.savedEdu[index] || this.state.eduCurrently[index]}
                                                   value={this.state.eduEnd[index] || ''} placeholder="yyyy-mm-dd"
                                                   onChange={(event) => this.onChangeEdu(event, index, 'eduEnd')}/>
                                            <label hidden={!this.state.showError['eduEnd' + index]} className='error-required'>This filed is required!</label>
                                        </div>
                                    </div>
                                    <div className="text-input">
                                        <label>GPA</label>
                                        <input className={this.state.savedEdu[index] ? "disabled" : ''} type="text" disabled={this.state.savedEdu[index]}
                                               value={this.state.eduGPA[index] || ''}
                                               onChange={(event) => this.onChangeEdu(event, index, 'eduGPA')}/>
                                    </div>
                                    <div className="text-input">
                                        <label>Activities and Societies</label>
                                        <input className={this.state.savedEdu[index] ? "disabled" : ''} type="text" disabled={this.state.savedEdu[index]}
                                               value={this.state.eduActivities[index] || ''}
                                               onChange={(event) => this.onChangeEdu(event, index, 'eduActivities')} />
                                    </div>
                                    <div className="btn-wrap" id={'edu'+index}>
                                        <button className="btn-custom-del" onClick={(event) => this.deleteEdu(event, index)}>Delete education</button>
                                        <button className="btn-custom" onClick={(event) => this.saveEdu(event, index)}>Save education</button>
                                    </div>
                                </div>
                            })
                        }
                        <div className="add-section" onClick={this.addEdu}>
                            <FontAwesomeIcon className="icon-plus" icon={faPlusCircle} />
                            <label>Add new education</label>
                        </div>
                        <h4>Job interests</h4>
                        <div className="text-input dropdown-input">
                            <label>Job type*</label>
                            <div className="dropdown-wrap">
                                <input type="dropdown" onClick={this.dropdownShowJobType} value={this.state.jobType || ''} readOnly={true}/>
                                <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowJobType} icon={faAngleDown} />
                            </div>
                            <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowJob}>
                                {
                                    jobTypeList.map((type, index) => {
                                        return <button className="dropdown-item" key={index} onClick={this.dropdownChooseJobType} value={type}>{type}</button>
                                    })
                                }
                            </div>
                            <label hidden={!this.state.showError['jobType']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input dropdown-input">
                            <label>Experience Level*</label>
                            <div className="dropdown-wrap">
                                <input type="dropdown" onClick={this.dropdownShowLevel} value={this.state.experienceLevel || ''} readOnly={true}/>
                                <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowLevel} icon={faAngleDown} />
                            </div>
                            <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowLevel}>
                                {
                                    experienceLevelList.map((type, index) => {
                                        return <button className="dropdown-item" key={index} onClick={this.dropdownChooseLevel} value={type}>{type}</button>
                                    })
                                }
                            </div>
                            <label hidden={!this.state.showError['experienceLevel']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input dropdown-input">
                            <label>Industry*</label>
                            <div className="dropdown-wrap">
                                <input type="text" list="cities" className="type-dropdown"
                                       onChange={this.dropdownChooseIndustry}
                                       aria-describedby="basic-addon1"/>
                                <datalist id="cities">
                                    {
                                        industryList.map((item, index) => {
                                            return <option key={index}>{item}</option>
                                        })
                                    }
                                </datalist>
                            </div>
                            <label hidden={!this.state.showError['industry']} className='error-required'>This filed is required!</label>
                        </div>
                        <div className="text-input dropdown-input">
                            <label>Business size*</label>
                            <div className="dropdown-wrap">
                                <input type="dropdown" onClick={this.dropdownShowBSize} value={this.state.bSize || ''} readOnly={true}/>
                                <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowBSize} icon={faAngleDown} />
                            </div>
                            <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowBSize}>
                                {
                                    bSizeList.map((type, index) => {
                                        return <button className="dropdown-item" key={index} onClick={this.dropdownChooseBSize} value={type}>{type}</button>
                                    })
                                }
                            </div>
                            <label hidden={!this.state.showError['bSize']} className='error-required'>This filed is required!</label>
                        </div>
                        <h4>Add up to 5 roles</h4>
                        <div className="text-input dropdown-input">
                            <label>Roles of Interest*</label>
                            <div className="dropdown-wrap">
                                <input type="dropdown" onClick={this.dropdownShowRoles} value={"Choose role"} readOnly={true}/>
                                <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowRoles} icon={faAngleDown} />
                            </div>
                            <div className="dropdown-menu-custom" hidden={!this.state.dropdownShowRoles}>
                                {
                                    rolesList.map((type, index) => {
                                        return <button className="dropdown-item" key={index} onClick={this.dropdownChooseRoles} value={type}>{type}</button>
                                    })
                                }
                            </div>
                            <label hidden={this.state.chosenRoles.length >= 1  || !this.state.showError['roles']} className='error-required'>Add at least 1 role</label>
                        </div>
                        <span hidden={this.state.chosenRoles.length === 0}>Chosen roles</span>
                        <div className="skills">
                            {
                                this.state.chosenRoles.map((role, index) => {
                                    return <div className="skill" key={index} >{role} <FontAwesomeIcon className="icon-close" onClick={() => this.removeRoll(index)} icon={faTimes} /></div>
                                })
                            }
                        </div>
                        <h4>Add up to 10 skills</h4>
                        <div className="text-input dropdown-input">
                            <label>Skills*</label>
                            <div className="dropdown-wrap">
                                <input type="dropdown" onClick={this.dropdownShowSkills} value={"Choose skill"} readOnly={true}/>
                                <FontAwesomeIcon className="icon-arrow-down" onClick={this.dropdownShowSkills} icon={faAngleDown} />
                            </div>
                            <div className="dropdown-menu-custom skills-dropdown" hidden={!this.state.dropdownShowSkills}>
                                {
                                    skillsList.map((type, index) => {
                                        return <button className="dropdown-item" key={index} onClick={this.dropdownChooseSkills} value={type}>{type}</button>
                                    })
                                }
                            </div>
                            <label hidden={this.state.chosenSkills.length >= 1  || !this.state.showError['skills']} className='error-required'>Add at least 1 skill</label>
                        </div>
                        <span hidden={this.state.chosenSkills.length === 0}>Chosen skills</span>
                        <div className="skills">
                            {
                                this.state.chosenSkills.map((skill, index) => {
                                    return <div className="skill" key={index} >{skill} <FontAwesomeIcon className="icon-close" onClick={() => this.removeSkill(index)} icon={faTimes} /></div>
                                })
                            }
                        </div>
                        <button className="submit-btn" onClick={this.createProfile}>Create the profile</button>
                    </form>
                </section>
                <Footer />
            </main>
        )
    }
}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(withRouter(ProfileInfoApplicant))
