import React from 'react'
import { connect } from 'react-redux'
import ApplicantProfile from '../../components/profile-applicant';
import RecruiterProfile from '../../components/profile-recruiter';
import {Auth} from "@aws-amplify/auth";
import {Redirect, withRouter} from "react-router";
import Spinner from "../../components/spinner";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authed: false,
            userType: null
        };
    }

    componentDidMount() {
        const { loaded } = this.props.dataReducer;
        if(!loaded)
            this.checkCurrentAuthSession();
        else {
            const { userType } = this.props.dataReducer;
            this.setState({
                userType: userType
            });
        }
    }

    checkCurrentAuthSession = async () => {
        try {
            const { token } = this.props.authReducer;
            const session = await Auth.currentSession();
            const jwtToken = session.getAccessToken().jwtToken;
            const currentUserInfo = await Auth.currentUserInfo();
            if(!currentUserInfo.attributes['email_verified']) {
                this.props.history.push('/confirm-email');
            }
            const userType = currentUserInfo.attributes['custom:role'];
            this.setState({
                userType: userType
            });
            if (token === jwtToken) {
                const name = currentUserInfo.attributes['name'];
                const lastName = currentUserInfo.attributes['family_name'];
                const email = currentUserInfo.attributes['email'];
                const company = currentUserInfo.attributes['custom:company'];
                const username = currentUserInfo.attributes['sub'];
                this.props.dispatch({
                    type: 'SET_USER_INFO',
                    username,
                    name,
                    lastName,
                    email,
                    company,
                    userType
                });
            }
            else {
                this.props.dispatch({
                    type: 'LOGOUT_REQUEST'
                });
                const { loggedOut } = this.props.authReducer;
                if (loggedOut) {
                    this.props.history.push('/login');
                }
            }

        } catch (err) {
            if(err.message && err.message.indexOf("Access Token has been revoked")!== -1) {
                this.props.dispatch({
                    type: 'LOGOUT_REQUEST'
                });
                const { loggedOut } = this.props.authReducer;
                if (loggedOut) {
                    this.props.history.push('/login');
                }
            }
        }
    };

    render() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        const { token } = this.props.authReducer;

        const userType =  this.state.userType;
        if (!token) {
            return <Redirect to='/login' />
        }
        if(userType === null) {
            return <div className='spinner-wrapper-whiter'>
                <span className='spinner' />
            </div>
        }
        const {showSpinner} = this.props.dataReducer;
        if(showSpinner) {
            return <Spinner />
        }
        return (
            <div className="content-wrap">
                {
                    userType === 'applicant' &&
                    <ApplicantProfile/>
                }
                {
                    userType === 'recruiter' &&
                    <RecruiterProfile />
                }
                {
                    userType !== 'applicant' && userType !== 'recruiter'  &&
                    <div className='spinner-wrapper-whiter'>
                        <span className='spinner' />
                    </div>
                }
            </div>
        )
    }


}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer
});

export default connect(mapStateToProps)(withRouter(Dashboard))
