import React from 'react'
import { connect } from 'react-redux'
import {Auth} from "@aws-amplify/auth";
import {Redirect} from "react-router";
import ProfileInfoApplicant from "../../components/info-applicant";
import ProfileInfoRecruiter from "../../components/info-recruiter";
import Spinner from "../../components/spinner";

class ProfileInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authed: false,
            userType: null
        };
    }

    componentDidMount() {
        const { loaded } = this.props.dataReducer;
        if(!loaded)
            this.checkCurrentAuthSession();
        if(this.props.location.search !=="") {
            const code = this.props.location.search.split('=')[1];
            const importDataClicked = true;
            this.props.dispatch({
                type: "LINKEDIN_ACCESS",
                code,
                importDataClicked
            })
        }
    }

    checkCurrentAuthSession = async () => {
        try {
            const { token } = this.props.authReducer;
            const session = await Auth.currentSession();
            const jwtToken = session.getAccessToken().jwtToken;
            const currentUserInfo = await Auth.currentUserInfo();
            console.log(currentUserInfo);
            const userType = currentUserInfo.attributes['custom:role'];
            this.setState({
                userType: userType
            });
            if (token === jwtToken) {
                const name = currentUserInfo.attributes['name'];
                const lastName = currentUserInfo.attributes['family_name'];
                const email = currentUserInfo.attributes['email'];
                const company = currentUserInfo.attributes['custom:company'];
                const username = currentUserInfo.attributes['sub'];
                this.props.dispatch({
                    type: 'SET_USER_INFO',
                    username,
                    name,
                    lastName,
                    email,
                    company
                });
            }
            else {
                this.props.dispatch({
                    type: 'LOGOUT_REQUEST'
                });
                const { loggedOut } = this.props.authReducer;
                if (loggedOut) {
                    this.props.history.push('/login');
                }
            }

        } catch (err) {
            // console.log('error fetching user info: ', err);
        }
    };

    render() {
        const { token } = this.props.authReducer;
        const userType =  this.props.authReducer.userType;

        if (!token) {
            return <Redirect to='/login' />
        }
        if(userType === null) {
            return <div className='spinner-wrapper'>
                <span className='spinner' />
            </div>
        }

        const {showSpinner} = this.props.dataReducer;
        if(showSpinner) {
            return <Spinner />
        }

        return (
            <div className="content-wrap">
                {
                    userType === 'applicant' ?
                        <ProfileInfoApplicant />
                        :
                        <ProfileInfoRecruiter />
                }
            </div>
        )
    }


}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer
});

export default connect(mapStateToProps)(ProfileInfo)
