import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import dataReducer from './dataReducer'
import authReducer from './authReducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  authReducer,
  dataReducer
});

export default createRootReducer;
