import React from 'react'
import { connect } from 'react-redux'
import '../../styles/style.scss';
import Register from '../../components/register-form';
import Footer from '../../components/footer';
import {Redirect} from "react-router";
import {Auth} from "@aws-amplify/auth";
import Spinner from "../../components/spinner";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: 'applicant',
            authed: false
        };
    }

    componentDidMount() {
        this.checkCurrentAuthSession();

    }

    checkCurrentAuthSession = async () => {
        try {
            const currentUserInfo = await Auth.currentUserInfo();
            const userType = currentUserInfo.attributes['custom:role'];
            const name = currentUserInfo.attributes['name'];
            const lastName = currentUserInfo.attributes['lastName'];
            const email = currentUserInfo.attributes['email'];
            const company = currentUserInfo.attributes['custom:company'];
            const username = currentUserInfo.attributes['sub'];
            this.setState({
                userType: userType
            });
            this.props.dispatch({
                type: 'SET_USER_INFO',
                username,
                name,
                lastName,
                email,
                company,
                userType
            });
        } catch (err) {
            // console.log('error fetching user info: ', err);
        }
    };


    render() {
        const { userType, token } = this.props.authReducer;
        const {showSpinner} = this.props.dataReducer;

        if (token) {
            return <Redirect to='/' />
        }

        if(showSpinner) {
            return <Spinner />
        }

        return (
            <main>
                <section className="section-login">
                    <div className="header">
                        <img src={require('../../assets/images/long-logo.svg')} alt="" className="human1"/>
                    </div>
                    <div className="section-content">
                        <img src={require('../../assets/images/browsers-img.svg')} alt="" className="browsers-img" />
                        <Register />
                    </div>
                </section>
                <section className="section-steps">
                    <div className="title">
                        <p>How it works?</p>
                    </div>
                    {
                        userType === 'applicant' ?
                            <div className="section-content">
                                <div className="step">
                                    <img src={require('../../assets/images/step1.svg')} className="first-img" alt=""/>
                                    <div className="step-text">
                                        <h2>Profile</h2>
                                        <p>Applicant creates profile</p>
                                    </div>
                                </div>
                                <div className="step">
                                    <img src={require('../../assets/images/step2.svg')} alt="" />
                                    <div className="step-text">
                                        <h2>Dream jobs</h2>
                                        <p>Choose your desired industry, size, and culture of the company.</p>
                                    </div>
                                </div>
                                <div className="step">
                                    <img src={require('../../assets/images/step3.svg')} alt="" />
                                    <div className="step-text">
                                        <h2>Place Roles</h2>
                                        <p>Applicant decides up to 5 roles to be considered for.</p>
                                    </div>
                                </div>
                                <div className="step">
                                    <img src={require('../../assets/images/step4.svg')} alt="" />
                                    <div className="step-text">
                                        <h2>Interview</h2>
                                        <p>Candidate invited to upload/record virtual interview</p>
                                    </div>
                                </div>
                            </div> :
                            <div className="section-content">
                                <div className="step">
                                    <img src={require('../../assets/images/step1.svg')} className="first-img"  alt=""/>
                                    <div className="step-text">
                                        <h2>Authenticate</h2>
                                        <p>Recruiter ‘claims’ their business or creates a profile</p>
                                    </div>
                                </div>
                                <div className="step">
                                    <img src={require('../../assets/images/step2-rec.svg')} alt="" />
                                    <div className="step-text">
                                        <h2>Introduction</h2>
                                        <p>Recruiter shares the company's background, values, and culture.</p>
                                    </div>
                                </div>
                                <div className="step">
                                    <img src={require('../../assets/images/step3.svg')} alt="" />
                                    <div className="step-text">
                                        <h2>Place Roles</h2>
                                        <p>Recruiter indicates which roles they are actively hiring</p>
                                    </div>
                                </div>
                                <div className="step">
                                    <img src={require('../../assets/images/step4.svg')} alt="" />
                                    <div className="step-text">
                                        <h2>Quality Leads</h2>
                                        <p>Quality leads automatically filtered, each with video introduction</p>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
                <section className="section-benefits">
                    <div className="title">
                        <p>Benefits</p>
                    </div>
                    {
                        userType === 'applicant' ?
                            <div className="section-content">
                                <div className="benefit-wrap">
                                    <img src={require('../../assets/images/benefits-img1.svg')} alt="" />
                                    <div className="benefit-text">
                                        <h2>AUTOMATIZATION</h2>
                                        <h1>Automated Functions</h1>
                                        <p>Job opportunities will come to you
                                            Automatic profile optimization - No more rewriting resumes
                                            Virtual interview opportunities</p>
                                    </div>
                                </div>
                                <div className="benefit-wrap second-div">
                                    <img src={require('../../assets/images/benefits-img2.svg')} alt="" className="second" />
                                    <div className="benefit-text">
                                        <h2>SECRET SAUCE</h2>
                                        <h1>Ease of Use and Support</h1>
                                        <p>Seamless process from account setup to job offers
                                            ATS supported resume scanning
                                            Interview prep support
                                        </p>
                                    </div>
                                </div>
                            </div> :
                            <div className="section-content">
                                <div className="benefit-wrap">
                                    <img src={require('../../assets/images/benefits-img1.svg')} alt="" />
                                    <div className="benefit-text">
                                        <h2>seamless process</h2>
                                        <h1>From Recruitment to Offer</h1>
                                        <p>Job postings will automatically render qualified candidates
                                            Seamlessly invite qualified candidates to a virtual interview
                                            Easily share candidate profile and interview details with team members
                                        </p>
                                    </div>
                                </div>
                                <div className="benefit-wrap second-div">
                                    <img src={require('../../assets/images/benefits-img2.svg')} alt="" className="second" />
                                    <div className="benefit-text">
                                        <h2>SECRET SAUCE</h2>
                                        <h1>Ease of Use and Analytics</h1>
                                        <p>Quick and easy account setup
                                            Qualified candidates automatically ranked
                                            Simple and seamless design to help make better decisions faster
                                        </p>
                                    </div>
                                </div>
                            </div>
                    }
                </section>
                <Footer />
            </main>


        )
    }


}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer,
    router: state.router
});

export default connect(mapStateToProps)(Home);
