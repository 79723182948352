import React from 'react'
import { connect } from 'react-redux'


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <footer>
                <a href="https://www.linkedin.com/company/konaty/"  target="_blank" rel="noopener noreferrer">
                    <img src={require('../../assets/images/linkedin-logo.svg')} alt="" />
                </a>
                <div className="about">
                    <ul>
                        <li>About</li><span>&#8226;</span>
                        <li>Support</li><span>&#8226;</span>
                        <li>Marketing</li>
                    </ul>
                </div>
                <div className="terms">
                    <ul>
                        <li>Terms of Use</li><span>&#8226;</span>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </footer>
        )
    }


}

const mapStateToProps = state => ({
    authReducer: state.authReducer,
    dataReducer: state.dataReducer
});

export default connect(mapStateToProps)(Footer)
