import { all, fork } from 'redux-saga/effects';
import authSaga from './sagas/authSaga';
import utilsSaga from './sagas/utilsSaga';

function * rootSaga () {
  yield all([
    fork(authSaga),
    fork(utilsSaga)
  ])
}

export default rootSaga
