const config = {
    Auth: {
        identityPoolId: 'us-west-2:e37605dd-ccb4-4ef9-996d-68523904cf2b',
        region: 'us-west-2',
        userPoolId: 'us-west-2_IU9tguhM2',
        userPoolWebClientId: '7a0d6530ht9mj29aceb212fvkd'
    },
    Storage: {
        region: 'us-west-2',
        bucket: 'konaty-app-uploads',
        identityPoolId: 'us-west-2:e37605dd-ccb4-4ef9-996d-68523904cf2b',
    }
};

export default config;


