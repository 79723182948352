export const USER_TYPE = 'USER_TYPE';
export const IS_USER_VERIFIED = 'IS_USER_VERIFIED';
export const AUTH_INFO = 'AUTH_INFO';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const LOGGING = 'LOGGING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SHOW_FORGOT_SUCCESS = 'SHOW_FORGOT_SUCCESS';
export const SHOW_FORGOT_FAILURE = 'SHOW_FORGOT_FAILURE';
export const FORGOT_MESSAGE_HIDE = 'FORGOT_MESSAGE_HIDE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAILED = 'VERIFY_FAILED';
export const SIGNUP_INFO = 'SIGNUP_INFO';
export const SIGN_IN_UNSUCCESSFUL = 'SIGN_IN_UNSUCCESSFUL';

const token = window.localStorage.getItem('token');
const idToken = window.localStorage.getItem('idToken');
const username = window.localStorage.getItem('username');

const email = window.localStorage.getItem('email');

const initialState = {
    userType: 'applicant',
    authed: false,
    verified: false,
    token,
    idToken,
    username,
    errorVerify: null,
    logging: false,
    forgotSuccess: false,
    forgotFailure: false,
    email,
    pass: null,
    registered: null,
    loggedOut: null,
    error: null,
    errorSignIn: null
};

export default function authReducer (state = initialState, { type, payload }) {
    switch (type) {
        case USER_TYPE: {
            const userType = payload.userType;
            return {...state, userType }
        }
        case LOGGING: {
            return { ...state, logging: payload.logging, error: payload.error }
        }
        case AUTH_SUCCESS: {
            return {
                ...state,
                token: payload.token,
                idToken: payload.idToken,
                logging: false,
                error: null,
                errorSignIn: null
            }
        }
        case AUTH_FAILURE: {
            return { ...state, error: payload.message, logging: false }
        }
        case LOGOUT_SUCCESS: {
            return {
                ...state,
                userType: null,
                token: null,
                idToken: null,
                error: null,
                username: null,
                loggedOut: true,
                registered: false
            }
        }
        case SHOW_FORGOT_SUCCESS: {
            return { ...state, forgotSuccess: true }
        }
        case FORGOT_MESSAGE_HIDE: {
            return { ...state, forgotSuccess: false, forgotFailure:false }
        }
        case SHOW_FORGOT_FAILURE: {
            return { ...state, forgotFailure: true }
        }
        case UPDATE_TOKEN: {
            return { ...state, token: payload.token, idToken: payload.idToken }
        }
        case VERIFY_SUCCESS: {
            return { ...state, verified: payload.verified }
        }
        case VERIFY_FAILED: {
            return { ...state, verified: payload.verified, errorVerify: payload.errorMsg }
        }
        case SIGNUP_INFO: {
            return { ...state, registered: payload.registered, email: payload.email, pass: payload.password, userType: payload.userType }
        }
        case SIGN_IN_UNSUCCESSFUL: {
            return { ...state, errorSignIn: payload.message }
        }
        default:
            return state;
    }
}
